import client from "../client/client";

class LinkReqToDelivService {
  getProjects({ direction, limit = 50, page = 0, sort }, value, cancelToken) {
    return client.post("/spp/projects", {
      payload: value,
      params: { page, limit, sort, direction },
      config: { cancelToken },
    });
  }
  getDeliverables({ projectId }, { page = 0, limit = 50, filter }, cancelToken) {
    return client.get(`/spp/projects/${projectId}/documents-out`, {
      params: { page, limit, filter },
      config: { cancelToken },
    });
  }
  getRequirementsForDeliverables({ projectId, deliverableId }, { page = 0, limit = 50, isCovered }, cancelToken) {
    return client.get(`/spp/projects/${projectId}/documents-out/${deliverableId}/coverage`, {
      params: { page, limit, isCovered },
      config: { cancelToken },
    });
  }
  getRequirementsForDeliverablesCount({ projectId, deliverableId }, cancelToken) {
    return client.get(`/spp/projects/${projectId}/documents-out/${deliverableId}/coverage/count`, {
      config: { cancelToken },
    });
  }
  getParagraphsForDeliverables({ projectId, deliverableId, informationId }, cancelToken) {
    return client.get(
      `/spp/projects/${projectId}/document-out/${deliverableId}/information/${informationId}/paragraph-out/links`,
      {
        config: { cancelToken },
      }
    );
  }
  deleteParagraphsLinkForDeliverables(
    { projectId, deliverableId, informationId, paragraphOutId, linkId },
    cancelToken
  ) {
    return client.delete(
      `/spp/projects/${projectId}/document-out/${deliverableId}/information/${informationId}/paragraph-out/${paragraphOutId}/links/${linkId}`,
      {
        config: { cancelToken },
      }
    );
  }
  createParagraphForDeliverables(
    { projectId, deliverableId, informationId },
    { content, contentHTML, contentXML, order_in_doc, page, title, paragraphId },
    cancelToken
  ) {
    return client.post(
      `/spp/projects/${projectId}/document-out/${deliverableId}/information/${informationId}/paragraph-out/links`,
      {
        payload: { content, contentHTML, contentXML, order_in_doc, title, page, paragraphId },
        config: { cancelToken },
      }
    );
  }
}
export default new LinkReqToDelivService();
