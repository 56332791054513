/* eslint-disable office-addins/no-context-sync-in-loop */
/* eslint-disable office-addins/no-navigational-load */
import { arrayBufferToBase64, downloadFile, isHeader } from "./function-utils";

/* global Word, Office */
class WordUtils {
  async insertTextToWord(text) {
    Word.run(async (context) => {
      context.document.getSelection().insertText(text);
    });
  }

  async insertSelectionToPlugin(onCallback) {
    await Office.context.document.getSelectedDataAsync(Office.CoercionType.Text, (result) => {
      if (result.status == Office.AsyncResultStatus.Failed) {
        console.error("Action failed. Error: " + result.error.message);
      } else {
        onCallback(result);
      }
    });
  }

  async openDocumentInNewFile({ data, filename }) {
    if (filename.includes(".docx")) {
      Word.run(function (context) {
        var newDoc = context.application.createDocument(arrayBufferToBase64(data));
        context.load(newDoc);
        return context
          .sync()
          .then(() => {
            newDoc.open();
            context.sync();
          })
          .catch(console.error);
      }).catch(console.error);
    }
    if (filename.includes(".pdf")) {
      Office.onReady((info) => {
        if (info.host === Office.HostType.Word) {
          if (Office.context.platform === Office.PlatformType.PC) {
            downloadFile({ data, filename, filetype: "zip" });
          } else if (Office.context.platform === Office.PlatformType.Mac) {
            downloadFile({ data, filename, filetype: "zip" });
          } else if (Office.context.platform === Office.PlatformType.OfficeOnline) {
            const blob = new Blob([data], { type: "application/pdf" });
            const url = URL.createObjectURL(blob);
            window.open(url, "_blank");
            URL.revokeObjectURL(url);
          }
        }
      });
    }
  }

  async getTitle({ content, html, onCallback, tag, xml }) {
    await Word.run(async (context) => {
      const selection = context.document.getSelection().paragraphs;
      selection.load("items");
      await context.sync();
      let prevParagraph = selection.items[0].getPreviousOrNullObject();
      prevParagraph.load("style,text, isNullObject, listItemOrNullObject/listString");
      await context.sync();
      while (!isHeader(prevParagraph?.style) && !prevParagraph.isNullObject) {
        prevParagraph = prevParagraph.getPreviousOrNullObject();
        prevParagraph.load("style,text, isNullObject, listItemOrNullObject/listString");
        await context.sync();
      }
      if (!prevParagraph.isNullObject) {
        onCallback({
          title: `${prevParagraph.listItemOrNullObject.listString || ""}${prevParagraph.text}`,
          content,
          html,
          xml,
          tag,
        });
      } else {
        // No title call back
        onCallback({ content, html, xml, tag });
      }
    }).catch(console.error);
  }

  async getInformationForAddParagraph(onCallback) {
    Word.run((context) => {
      var selection = context.document.getSelection();
      const htmlValue = selection.getHtml();
      const xmlValue = selection.getOoxml();
      selection.load("text,contentControls/items/tag");
      context.sync().then(() => {
        this.getTitle({
          onCallback,
          content: selection.text,
          // eslint-disable-next-line office-addins/load-object-before-read
          html: htmlValue.value,
          // eslint-disable-next-line office-addins/load-object-before-read
          xml: xmlValue.value,
          tag: selection.contentControls?.items[0]?.tag,
        });
        return context.sync();
      });
    }).catch(console.error);
  }

  async insertContentControl({ id, informationId, paragraphOutId }) {
    Word.run((context) => {
      var selection = context.document.getSelection();
      selection.load("text");
      return context.sync().then(() => {
        var contentControl = selection.insertContentControl();
        contentControl.title = `Req: ${informationId}`;
        contentControl.tag = `Req: ${informationId} | paragraphOutId: ${paragraphOutId} | linkId:${id}`;
        contentControl.appearance = "BoundingBox";
        contentControl.color = "#3880E8";
        return context.sync();
      });
    }).catch(console.error);
  }

  async scrollToContentControl({ id, informationId, paragraphOutId }) {
    Word.run((context) => {
      var contentControlTag = `Req: ${informationId} | paragraphOutId: ${paragraphOutId} | linkId:${id}`;
      var contentControls = context.document.contentControls;
      contentControls.load("items/title,items/tag");
      return context.sync().then(() => {
        var targetControl = null;
        for (var i = 0; i < contentControls.items.length; i++) {
          if (contentControls.items[i].tag === contentControlTag) {
            targetControl = contentControls.items[i];
            break;
          }
        }
        if (targetControl) {
          targetControl.load("text");
          context.sync().then(() => {});
          targetControl.select();
          return context.sync();
        } else {
          console.error("Content control not found.");
        }
      });
    }).catch(console.error);
  }
  async removeContentControl({ id, informationId, paragraphOutId }) {
    Word.run((context) => {
      var contentControlTag = `Req: ${informationId} | paragraphOutId: ${paragraphOutId} | linkId:${id}`;
      var contentControls = context.document.contentControls;
      contentControls.load("items/title,items/tag");
      return context.sync().then(() => {
        var targetControl = null;
        for (var i = 0; i < contentControls.items.length; i++) {
          if (contentControls.items[i].tag === contentControlTag) {
            targetControl = contentControls.items[i];
            break;
          }
        }
        if (targetControl) {
          targetControl.delete(true);
          return context.sync();
        } else {
          console.error("Content control was not found.");
        }
      });
    }).catch(console.error);
  }
}
export default new WordUtils();
