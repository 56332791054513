import { createSlice } from "@reduxjs/toolkit";
import { TABS } from "../constants/tabs";

const initialState = {
  selectedTab: TABS.ALL,
  updateReqList: true,
};
const LinkRequirementToDeliverableSlice = createSlice({
  name: "linkRequirementToDeliverable",
  initialState,
  reducers: {
    setSelectedTab: (state, { payload }) => ({
      ...state,
      selectedTab: payload,
    }),
    setUpdateReqList: (state, { payload }) => ({
      ...state,
      updateReqList: payload,
    }),
  },
});
export const { setSelectedTab, setUpdateReqList } = LinkRequirementToDeliverableSlice.actions;
export default LinkRequirementToDeliverableSlice.reducer;
