import { MenuItem, MenuList } from "@mui/material";
import React, { useRef, useState, useMemo, useEffect } from "react";
import { CustomIconButton, DropDown, icon, IconComponent } from "..";
import { FILE_TYPES } from "../../constants/file-types";
import { dangerousSetInnerHTML, debounce, isNonEmptyArray } from "../../utils";
import BreadcrumbButton from "./BreadcrumbButton";
import styles from "./CustomBreadcrumb.module.css";

const CustomBreadcrumb = ({
  autoDisplayedItems = false,
  breadcrumbClassName = "",
  charsKeptInEnd = 12,
  className = "",
  closeOnLeave = false,
  disabled = false,
  enableFirstLocationCut = false,
  isDangerousSetInnerHTML = false,
  locations,
  onOptionSelection = null,
  openOnHover = false,
}) => {
  const anchorRef = useRef();
  const [open, setOpen] = useState(false);
  const [displayedCount, setDisplayedCount] = useState(0);
  const [firstLocationWidth, setFirstLocationWidth] = useState(0);
  const startLocations = useMemo(() => {
    if (locations.length > 1) {
      return locations.slice(0, displayedCount);
    }
    return [];
  }, [locations, displayedCount]);
  const lastLocation = useMemo(() => locations[locations.length - 1], [locations]);
  const dropDownLocations = useMemo(() => {
    if (locations.length > 2) {
      return locations.slice(displayedCount, locations.length - 1);
    }
    return [];
  }, [locations, displayedCount]);
  useEffect(() => {
    const handleWidthSize = () => {
      setFirstLocationWidth(document.getElementById(`breadCrumbContainer${locations[0]?.label}`)?.offsetWidth);
    };
    if (enableFirstLocationCut) {
      window.addEventListener("resize", handleWidthSize);
    }
    return () => {
      window.removeEventListener("resize", handleWidthSize);
    };
  }, [locations, enableFirstLocationCut]);
  // To run setFirstLocationWidth once and after components are rendered
  useEffect(() => {
    if (enableFirstLocationCut) {
      setFirstLocationWidth(document.getElementById(`breadCrumbContainer${locations[0]?.label}`)?.offsetWidth);
    }
  });
  useEffect(() => {
    const computeToDisplay = () => {
      if (Array.isArray(locations) && locations.length > 1) {
        let index = 1;
        let width = 126 + locations[locations.length - 1].label.length * 6.75;
        const containerWidth = document.getElementById("breadCrumbContainer")?.offsetWidth;
        if (width < containerWidth) {
          for (let i = 1; i < locations.length - 1; i++) {
            width += locations[i].label.length * 6.75 + 44;
            if (width < containerWidth) {
              index += 1;
            } else {
              break;
            }
          }
        }
        setDisplayedCount(index);
      }
    };
    if (autoDisplayedItems) {
      window.addEventListener(
        "resize",
        debounce(() => computeToDisplay(), 500)
      );
    } else {
      setDisplayedCount(1);
    }
    computeToDisplay();
    return () => {
      window.removeEventListener(
        "resize",
        debounce(() => computeToDisplay(), 500)
      );
    };
  }, [autoDisplayedItems, locations]);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const Separator = () => (
    <IconComponent className={styles.separator} color="var(--color-dark-grey-1)" icon={icon.faChevronRight} size="xs" />
  );
  const handleSelectOption = (location) => {
    if (onOptionSelection) {
      onOptionSelection(location ? { ...location, type: FILE_TYPES.FOLDER } : null);
    }
    handleClose();
  };
  const handleLastElementWidth = () => {
    let width = 0;
    if (locations?.length === 1) {
      return 0;
    }
    if (firstLocationWidth) {
      width += firstLocationWidth;
    }
    if (isNonEmptyArray(dropDownLocations)) {
      width += 110;
    } else {
      width += 50;
    }
    return width;
  };
  if (Array.isArray(startLocations) && lastLocation) {
    return (
      <span className={className}>
        {startLocations.length > 0 && (
          <>
            {startLocations.map((newLocation) => (
              <React.Fragment key={newLocation.id}>
                <BreadcrumbButton
                  charsKeptInEnd={charsKeptInEnd}
                  className={breadcrumbClassName}
                  disabled={disabled}
                  id={`breadCrumbContainer${locations[0]?.label}`}
                  location={newLocation}
                  style={
                    (enableFirstLocationCut && {
                      maxWidth: `calc(50% - ${isNonEmptyArray(dropDownLocations) ? 56 : 20}px)`,
                    }) ||
                    {}
                  }
                  onSelectOption={onOptionSelection}
                />
                <Separator />
              </React.Fragment>
            ))}
            {Array.isArray(dropDownLocations) && dropDownLocations.length > 0 && (
              <span
                className={styles.breadcrumb__collapseContainer}
                role="presentation"
                onMouseEnter={() => {
                  if (openOnHover) {
                    handleOpen();
                  }
                }}
                onMouseLeave={() => {
                  if (closeOnLeave) {
                    handleClose();
                  }
                }}
              >
                <CustomIconButton
                  ref={anchorRef}
                  btnClassName={styles.collapseButto}
                  disabled={disabled}
                  icon={icon.faEllipsisH}
                  iconColor="var(--color-dark-grey-1)"
                  size="sm"
                  onClick={handleOpen}
                />
                <Separator />
              </span>
            )}
          </>
        )}
        <BreadcrumbButton
          disabled
          charsKeptInEnd={charsKeptInEnd}
          className={breadcrumbClassName}
          isDangerousSetInnerHTML={isDangerousSetInnerHTML}
          location={lastLocation}
          style={
            (enableFirstLocationCut && {
              maxWidth: `calc(100% - ${handleLastElementWidth()}px)`,
            }) ||
            {}
          }
        />
        <DropDown ref={anchorRef} open={open} onClose={handleClose}>
          <MenuList>
            {isNonEmptyArray(dropDownLocations) &&
              dropDownLocations.map(
                (location, index) =>
                  location.label && (
                    <MenuItem
                      key={location.id}
                      disabled={!onOptionSelection}
                      onClick={() => handleSelectOption(location)}
                    >
                      <span style={{ paddingLeft: `${(index - 1) * 8}px` }}>
                        <IconComponent
                          className={styles.folderIcon}
                          color="var(--color-light-grey-2)"
                          icon={icon.faFolder}
                        />
                        {(isDangerousSetInnerHTML && dangerousSetInnerHTML({ text: location.label })) || location.label}
                      </span>
                    </MenuItem>
                  )
              )}
          </MenuList>
        </DropDown>
      </span>
    );
  }
  return null;
};

export default CustomBreadcrumb;
