import {
  faAnglesLeft,
  faAlignCenter,
  faAlignLeft,
  faArrowDown,
  faArrowDownWideShort,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowUpRightFromSquare,
  faArrowsLeftRightToLine,
  faBan,
  faBars,
  faBookmark,
  faBoxArchive,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCheck,
  faChevronCircleUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircleCheck,
  faCircleMinus,
  faCopy,
  faComments,
  faDownload,
  faEllipsisH,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFile,
  faFileLines,
  faFilePdf,
  faFileWord,
  faFlag,
  faFolder,
  faGear,
  faGraduationCap,
  faHammer,
  faHandshakeAngle,
  faHashtag,
  faHome,
  faInfoCircle,
  faLayerGroup,
  faLightbulb,
  faLink,
  faLock,
  faMagicWandSparkles,
  faMagnifyingGlass,
  faMinus,
  faMinusSquare,
  faPen,
  faPenFancy,
  faPenToSquare,
  faPlus,
  faPlusSquare,
  faQuestionCircle,
  faShieldHalved,
  faSitemap,
  faSort,
  faSortDown,
  faSortUp,
  faStar,
  faTag,
  faTimes,
  faTrash,
  faUser,
  faUserTie,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import {
  faBookmark as faBookmarkRegular,
  faCirclePlay as faCirclePlayRegular,
  faClock as faClockRegular,
  faObjectGroup as faObjectGroupRegular,
} from "@fortawesome/free-regular-svg-icons";
export default {
  faAnglesLeft,
  faAlignCenter,
  faAlignLeft,
  faArrowDown,
  faArrowDownWideShort,
  faArrowLeft,
  faArrowRight,
  faArrowUp,
  faArrowUpRightFromSquare,
  faArrowsLeftRightToLine,
  faBan,
  faBars,
  faBookmark,
  faBookmarkRegular,
  faBoxArchive,
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faCaretUp,
  faCheck,
  faChevronCircleUp,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faCircleCheck,
  faCircleMinus,
  faCirclePlayRegular,
  faClockRegular,
  faCopy,
  faComments,
  faDownload,
  faEllipsisH,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFile,
  faFileLines,
  faFilePdf,
  faFileWord,
  faFlag,
  faFolder,
  faGear,
  faGraduationCap,
  faHammer,
  faHandshakeAngle,
  faHashtag,
  faHome,
  faInfoCircle,
  faLayerGroup,
  faLightbulb,
  faLink,
  faLock,
  faMagicWandSparkles,
  faMagnifyingGlass,
  faMinus,
  faMinusSquare,
  faObjectGroupRegular,
  faPen,
  faPenFancy,
  faPenToSquare,
  faPlus,
  faPlusSquare,
  faQuestionCircle,
  faShieldHalved,
  faSitemap,
  faSort,
  faSortDown,
  faSortUp,
  faStar,
  faTag,
  faTimes,
  faTrash,
  faUser,
  faUserTie,
  faXmark,
};
