import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  CircularLoader,
  CustomButton,
  CustomIconButton,
  icon,
  SidePanel,
  SidePanelContent,
} from "../../../../common/components";
import styles from "./RequirementSidePanel.module.css";
import { translate } from "../../../../common/providers";
import ParagraphRow from "./ParagraphRow";
import { isNonEmptyArray, WordUtils } from "../../../../common/utils";
import { useApi } from "../../../../common/hooks";
import { LinkReqToDelivService } from "../../../../api";
import { useSelector } from "react-redux";

const paragraphIdRegex = /paragraphOutId:\s*(\d+)/;
const newLineRegex = /[\f\r]/g;
// eslint-disable-next-line no-control-regex
const spaceRegex = /[\u000b\r]/g;

const RequirementSidePanel = ({ content, coverage, isCovered, open, onClose, reqId, selectedLinkId }) => {
  const { call: getParagraphsForDeliverables } = useApi(LinkReqToDelivService.getParagraphsForDeliverables);
  const { call: createParagraphForDeliverables } = useApi(LinkReqToDelivService.createParagraphForDeliverables);
  const { call: deleteParagraphsLinkForDeliverables } = useApi(
    LinkReqToDelivService.deleteParagraphsLinkForDeliverables
  );
  const deliverableId = useSelector(({ context }) => context.deliverable?.id);
  const projectId = useSelector(({ context }) => context.projectReqToDeliv?.id);
  const [isContentOverflowing, setIsContentOverflowing] = useState(false);
  const [isLoading, setIsLoading] = useState();
  const [hide, setHide] = useState(true);
  const [paragraphs, setParagraphs] = useState([]);
  const [isAdding, setIsAdding] = useState(false);
  const textRef = useRef(null);
  const scrollRef = useRef(null);
  const handleContentOverflow = useCallback(() => {
    setIsContentOverflowing(textRef.current?.scrollHeight > textRef.current?.offsetHeight);
  }, [textRef]);
  useEffect(() => {
    if (open) {
      setIsLoading(true);
      getParagraphsForDeliverables({ deliverableId, projectId, informationId: reqId })
        .then((data) => setParagraphs(data))
        .catch(console.error)
        .finally(() => setIsLoading(false));
    }
  }, [open, deliverableId, projectId]);
  useEffect(() => {
    if (open) {
      handleContentOverflow();
    }
  }, [open]);
  useEffect(() => {
    window.addEventListener("resize", handleContentOverflow);
    return () => {
      window.removeEventListener("resize", handleContentOverflow);
    };
  }, []);
  useEffect(() => {
    setTimeout(() => {
      if (open && scrollRef?.current && !isLoading) {
        scrollRef.current.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 500);
  }, [open, scrollRef, isLoading]);
  const handleShowMore = () => {
    setHide((prev) => !prev);
  };
  const sidePanelHeader = () => {
    return (
      <div className={styles.sidePanelHeaderContent}>
        <div>
          <CustomIconButton icon={icon.faChevronLeft} size="sm" onClick={onClose} />
        </div>
        <div className={styles.sidePanelHeaderContent__mainHeader}>
          <div className={styles.sidePanelHeaderContent__topLine}>
            <div className={styles.reqId}>REQ_{reqId}</div>
            <div className={styles.sidePanelHeaderContent__rightSide}>
              <span className={styles.cover} data-is-covered={isCovered}>
                {coverage}
              </span>
              <CustomIconButton icon={icon.faEllipsisH} size="sm" />
            </div>
          </div>
          <div>
            <div ref={textRef} className={styles.content} data-hide={hide}>
              {content}
            </div>
            {isContentOverflowing && (
              <CustomButton className={styles.informationContent__btn} color="primary" onClick={handleShowMore}>
                {(hide && translate("common:btn.show-more")) || translate("common:btn.show-less")}
              </CustomButton>
            )}
          </div>
        </div>
      </div>
    );
  };
  const handleCallBack = ({ content, html, tag, title, xml }) => {
    const paragraphId = parseInt(tag?.match(paragraphIdRegex)?.[1]) || undefined;
    createParagraphForDeliverables(
      { deliverableId, projectId, informationId: reqId },
      {
        contentHTML: html,
        contentXML: xml,
        content: content.replace(newLineRegex, "\n").replace(spaceRegex, " "),
        paragraphId,
        title,
        order_in_doc: 1,
        page: 1,
      }
    )
      .then((data) => {
        WordUtils.insertContentControl(data);
        setParagraphs((prev) => [...prev, data]);
        setIsAdding(false);
      })
      .catch(console.error);
  };
  const handleAddParagraph = () => {
    setIsAdding(true);
    WordUtils.getInformationForAddParagraph(handleCallBack);
  };
  const handleDeleteLink = (pargraphToDelete) => {
    deleteParagraphsLinkForDeliverables({
      projectId,
      deliverableId,
      informationId: reqId,
      paragraphOutId: pargraphToDelete.paragraphOutId,
      linkId: pargraphToDelete.id,
    })
      .then(() => {
        setParagraphs((prev) => prev.filter((p) => p.id !== pargraphToDelete.id));
        WordUtils.removeContentControl(pargraphToDelete);
      })
      .catch(console.error);
  };
  return (
    <SidePanel open={open} onClose={onClose}>
      <SidePanelContent className={styles.sidePanelContent} customHeader={sidePanelHeader()}>
        <div className={styles.sidePanelContent__container}>
          <div className={styles.sidePanelContent__addButton}>
            <CustomButton disabled={isAdding} startIcon={icon.faPlus} variant="contained" onClick={handleAddParagraph}>
              {isAdding ? <CircularLoader size={16} /> : translate("common:btn.add-selection")}
            </CustomButton>
          </div>
          <div className={styles.deliverablesList}>
            {(isLoading && <CircularLoader className={styles.circularLoader} />) || (
              <>
                {isNonEmptyArray(paragraphs) &&
                  paragraphs.map((paragraph) => (
                    <ParagraphRow
                      key={paragraph.id}
                      ref={scrollRef}
                      isSelected={paragraph.id === selectedLinkId}
                      row={paragraph}
                      onDelete={handleDeleteLink}
                    />
                  ))}
              </>
            )}
          </div>
        </div>
      </SidePanelContent>
    </SidePanel>
  );
};

export default RequirementSidePanel;
