import {
  KEY_DELIVERABLE_ID,
  KEY_DELIVERABLE_NAME,
  KEY_DOMAIN,
  KEY_LANG,
  KEY_PROJECT_ID,
  KEY_PROJECT_NAME,
  KEY_PROJECT_REQ_TO_DELIV_ID,
  KEY_PROJECT_REQ_TO_DELIV_NAME,
} from "./keys/keys";
import { WebSocket } from "../../api";

class ContextProvider {
  #isLogged;

  constructor() {
    this.localStorage = window.localStorage;
    this.sessionStorage = window.sessionStorage;
    this.#isLogged = false;
    this.bearerToken = "";
  }

  login(token) {
    if (!token || !token.includes("Bearer")) {
      this.logout();
      return this.#isLogged;
    }
    this.#isLogged = true;
    this.bearerToken = token;
    return this.#isLogged;
  }

  logout() {
    this.#isLogged = false;
    this.bearerToken = "";
    this.sessionStorage.clear();
    WebSocket.disconnect();
  }

  isLogged() {
    return this.#isLogged;
  }

  getToken() {
    return this.bearerToken;
  }

  getLang() {
    return this.localStorage.getItem(KEY_LANG);
  }

  setLang(lang) {
    this.localStorage.setItem(KEY_LANG, lang || "en");
  }
  setDeliverable(deliverable) {
    this.localStorage.setItem(KEY_DELIVERABLE_NAME, deliverable.name);
    this.localStorage.setItem(KEY_DELIVERABLE_ID, deliverable.id);
  }

  setProject(project) {
    this.localStorage.setItem(KEY_PROJECT_NAME, project.name);
    this.localStorage.setItem(KEY_PROJECT_ID, project.id);
  }
  setProjectReqToDeliv(project) {
    this.localStorage.setItem(KEY_PROJECT_REQ_TO_DELIV_NAME, project.name);
    this.localStorage.setItem(KEY_PROJECT_REQ_TO_DELIV_ID, project.id);
  }
  getDeliverable() {
    const name = this.localStorage.getItem(KEY_DELIVERABLE_NAME);
    const id = this.localStorage.getItem(KEY_DELIVERABLE_ID);
    return { name: name === "undefined" ? undefined : name, id: id === "undefined" ? undefined : parseInt(id) };
  }
  getProject() {
    const name = this.localStorage.getItem(KEY_PROJECT_NAME);
    const id = this.localStorage.getItem(KEY_PROJECT_ID);
    return { name: name === "undefined" ? undefined : name, id: id === "undefined" ? undefined : parseInt(id) };
  }
  getProjectReqToDeliv() {
    const name = this.localStorage.getItem(KEY_PROJECT_REQ_TO_DELIV_NAME);
    const id = this.localStorage.getItem(KEY_PROJECT_REQ_TO_DELIV_ID);
    return { name: name === "undefined" ? undefined : name, id: id === "undefined" ? undefined : parseInt(id) };
  }
  setDomain(domain) {
    this.localStorage.setItem(KEY_DOMAIN, domain);
  }

  getDomain() {
    return this.localStorage.getItem(KEY_DOMAIN);
  }
}

export default new ContextProvider();
