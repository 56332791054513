import React, { useEffect, useMemo, useState } from "react";
import { ProjectSelection, Search, EmptyState } from "../..";
import { IdentifiedContents } from "./components";
import { translate } from "../../../../../common/providers";
import { ViewTabs } from "../../../../../common/components";
import { useDispatch, useSelector } from "react-redux";
import { SEARCH_TABS } from "../../../constants/tabs";
import { setSelectedSearchTab } from "../../../slice/multi-project-search-slice";
import stylesTab from "../../../MultiProjectSearch.module.css";
import styles from "./SearchContent.module.css";
import { useApi } from "../../../../../common/hooks";
import { DocumentService } from "../../../../../api";
const SearchContents = () => {
  const selectedSearchTab = useSelector(({ multiProjectSearch }) => multiProjectSearch.selectedSearchTab);
  const { call: getProjectDeliverablesExist } = useApi(DocumentService.getProjectDeliverablesExist);
  const projectId = useSelector(({ context }) => context.project?.id);
  const [deliverablesExist, setDeliverablesExist] = useState(true);
  const dispatch = useDispatch();
  const tabHeaders = useMemo(
    () => [
      {
        id: SEARCH_TABS.PROJECT,
        label: translate("multi-projects-search.tab-header.project"),
        tab: "ProjectTab",
      },
      {
        id: SEARCH_TABS.DELIVERABLE,
        label: translate("multi-projects-search.tab-header.deliverable"),
        tab: "DeliverableTab",
      },
    ],
    []
  );
  useEffect(() => {
    getProjectDeliverablesExist()
      .then((data) => setDeliverablesExist(data))
      .catch(console.error);
  }, []);

  const handleChangeTab = (_, tab) => {
    dispatch(setSelectedSearchTab(tab));
  };

  return (
    <>
      <ViewTabs
        className={stylesTab.tabs}
        selectedTab={selectedSearchTab}
        tabIdentifier="id"
        tabs={tabHeaders}
        onChange={handleChangeTab}
      />
      {((deliverablesExist || selectedSearchTab === SEARCH_TABS.PROJECT) && (
        <>
          {(((selectedSearchTab === SEARCH_TABS.PROJECT && projectId) ||
            selectedSearchTab === SEARCH_TABS.DELIVERABLE) && (
            <div className={styles.container}>
              <Search />
              <IdentifiedContents />
            </div>
          )) || <ProjectSelection />}
        </>
      )) || <EmptyState />}
    </>
  );
};

export default SearchContents;
