import React, { useEffect, useState } from "react";
import { SelectionContainer } from "../";
import { LinkReqToDelivService } from "../../../../../api";
import { useApi } from "../../../../../common/hooks";
import styles from "../EnvironmentSelection.module.css";
import { translate, translateDate } from "../../../../../common/providers";
import { Radio } from "@mui/material";
import { CustomTooltip, icon, IconComponent } from "../../../../../common/components";
import { useDispatch, useSelector } from "react-redux";
import {
  setDeliverable,
  setDeliverableToUpdate,
  setProjectReqToDeliv,
  setProjectReqToDelivToUpdate,
} from "../../../../../common/slice";
import { NavUtils } from "../../../../../navigation";
import { useHistory } from "react-router-dom";
import { setUpdateReqList } from "../../../slice/link-requirement-to-deliverable-slice";

const ProjectSelection = () => {
  const { call: getProjects } = useApi(LinkReqToDelivService.getProjects);
  const projectReqToDeliv = useSelector(({ context }) => context.projectReqToDeliv);
  const [request, setRequest] = useState(null);
  const [sort, setSort] = useState("");
  const [direction, setDirection] = useState("DESC");
  const [selectedProject, setSelectedProject] = useState(projectReqToDeliv);
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    setRequest(
      () =>
        ({ page, limit, search }) =>
          getProjects({ page, limit, direction, sort }, search ? { value: search } : undefined)
    );
  }, [direction, sort]);
  const handleSelectProject = (row) => {
    setSelectedProject(row);
    dispatch(setDeliverable({}));
    dispatch(setUpdateReqList(true));
  };
  const rowRenderer = (row) => {
    return (
      <div className={styles.rowContainer} role="presentation" onClick={() => handleSelectProject(row)}>
        <Radio radio checked={row.id === selectedProject.id} />
        <div className={styles.date}>{translateDate(row.created)}</div>
        <CustomTooltip showWhenOverflow title={row.name}>
          <div className={styles.name}>{row.name}</div>
        </CustomTooltip>
      </div>
    );
  };
  const handleSort = (newSort) => {
    if (sort === newSort) {
      setDirection((prev) => (prev === "ASC" ? "DESC" : "ASC"));
    } else {
      setSort(newSort);
      setDirection("ASC");
    }
  };
  const handleSortIconDisplay = (cellSort) => {
    if (cellSort === sort) {
      if (direction === "ASC") {
        return icon.faSortUp;
      }
      return icon.faSortDown;
    }
    return icon.faSort;
  };
  const headerRow = () => (
    <div className={styles.headerRowContainer}>
      <div className={styles.radio} />
      <div className={styles.date} role="presentation" onClick={() => handleSort("created")}>
        {translate("common:project-selection-side-pane.date")}
        <IconComponent
          className={styles.sort__icon}
          color={sort === "created" ? "var(--color-dark-grey-2)" : "var(--color-light-grey-3)"}
          icon={handleSortIconDisplay("created")}
        />
      </div>
      <div className={styles.name} role="presentation" onClick={() => handleSort("name")}>
        {translate("common:project-selection-side-pane.name")}
        <IconComponent
          className={styles.sort__icon}
          color={sort === "name" ? "var(--color-dark-grey-2)" : "var(--color-light-grey-3)"}
          icon={handleSortIconDisplay("name")}
        />
      </div>
    </div>
  );
  const handleValidate = () => {
    dispatch(setProjectReqToDeliv({ name: selectedProject.name, id: selectedProject.id }));
    dispatch(setProjectReqToDelivToUpdate(false));
    dispatch(setDeliverableToUpdate(true));
  };
  const handleBack = () => {
    history.push(NavUtils.gotoMainMenu());
  };
  return (
    <SelectionContainer
      disableValidate={!selectedProject}
      headerRow={headerRow}
      request={request}
      rowRenderer={rowRenderer}
      title="Sélectionner un projet"
      onBack={handleBack}
      onValidate={handleValidate}
    />
  );
};

export default ProjectSelection;
