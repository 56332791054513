import { createSlice } from "@reduxjs/toolkit";
import { getPage } from "./multi-project-search-thunk";
import { SEARCH_TABS, TABS } from "../constants/tabs";
import { OPERATORS } from "../../../common/constants";
import { removeNullFilters } from "./multi-project-search-utils";

const initialState = {
  content: [],
  selectedTab: TABS.SEARCH,
  selectedSearchTab: SEARCH_TABS.PROJECT,
  selectedQuickActions: [],
  loadingAnswer: false,
  currentEditContentIndex: 1,
  tourEnabled: false,
  filters: { separator: OPERATORS.AND, input: "", granularity: [] },
  projectFilters: { separator: OPERATORS.AND, Type: "InformationSearchFilterDTO" },
};
const multiProjectSearchSlice = createSlice({
  name: "multiProjectSearch",
  initialState,
  reducers: {
    resetContent: (state) => ({
      ...state,
      content: [],
    }),
    setLoadingAnswer: (state, { payload }) => ({ ...state, loadingAnswer: payload }),
    setSelectedTab: (state, { payload }) => ({
      ...state,
      selectedTab: payload,
    }),
    incrementEditContentIndex: (state) => ({
      ...state,
      currentEditContentIndex: state.currentEditContentIndex + 1,
    }),
    setEditContentIndex: (state, { payload }) => ({
      ...state,
      currentEditContentIndex: payload,
    }),
    setTourEnabled: (state, { payload }) => ({
      ...state,
      tourEnabled: payload,
    }),
    decrementEditContentIndex: (state) => ({
      ...state,
      currentEditContentIndex: state.currentEditContentIndex - 1,
    }),
    setSelectedSearchTab: (state, { payload }) => ({
      ...state,
      selectedSearchTab: payload,
    }),
    setFilters: (state, { payload }) => {
      const newFilters = removeNullFilters(payload);
      return {
        ...state,
        filters: newFilters,
      };
    },
    setProjectFilters: (state, { payload: filters }) => {
      const newFilters = removeNullFilters(filters);
      return {
        ...state,
        projectFilters: newFilters,
      };
    },
    setSelectedQuickAction: (state, { payload }) => ({ ...state, selectedQuickActions: payload }),
  },
  extraReducers: (builder) => {
    builder.addCase(getPage.pending, (state, action) => {
      const { page } = action.meta.arg;
      return {
        ...state,
        content: [...state.content, { page, isLoading: true }],
      };
    });
    builder.addCase(getPage.fulfilled, (state, action) => {
      const { page } = action.meta.arg;
      const { payload } = action;
      const blobImage = URL.createObjectURL(payload);
      return {
        ...state,
        content: state.content.map((c) =>
          c.page === page ? { ...c, page, image: blobImage, isLoading: false } : { ...c, truePage: c.page }
        ),
      };
    });
    builder.addCase(getPage.rejected, (state, action) => {
      const { page } = action.meta.arg;
      return {
        ...state,
        content: state.content.map((c) => (c.page === page ? { ...c, page, image: null, isLoading: false } : c)),
      };
    });
  },
});
export { getPage };
export const {
  resetContent,
  setSelectedTab,
  setSelectedSearchTab,
  setFilters,
  setTourEnabled,
  setProjectFilters,
  incrementEditContentIndex,
  setEditContentIndex,
  decrementEditContentIndex,
  setLoadingAnswer,
  setSelectedQuickAction,
} = multiProjectSearchSlice.actions;
export default multiProjectSearchSlice.reducer;
