import React, { useEffect, useMemo, useRef, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { ApiService } from "../api";
import { getFlags, getSegFlags, getUser } from "../common/slice";
import styles from "./UserBar.module.css";
import { aitendersLogo } from "../common/resources/images";
import { CustomIconButton, icon } from "../common/components";
import { KeycloakService } from "../sevices";
import { Menus, SecondaryMenu } from "./components";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import { translate } from "../common/providers";
const PATHS_TO_DISABLE = ["/", "/main-menu", "/company-selection"];

const UserBar = () => {
  const company = useSelector(({ context }) => context.company);
  const user = useSelector(({ context }) => context.user);
  const projectWritePlugin = useSelector(({ context }) => context.project);
  const projectReqToDeliv = useSelector(({ context }) => context.projectReqToDeliv);
  const deliverableName = useSelector(({ context }) => context.deliverable?.name);
  const domain = useSelector(({ context }) => context.domain);
  const isLogged = KeycloakService.isLoggedIn();
  const [menuOpened, setMenuOpened] = useState(false);
  const [isInGlobalPath, setIsInGlobalPath] = useState(false);
  const cancelTokenSourceRef = useRef(null);
  const anchorRef = useRef();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentProject = useMemo(() => {
    if (location.pathname === "/multi-project-search") {
      return projectWritePlugin;
    }
    if (location.pathname === "/link-requirement-to-deliverable") {
      return projectReqToDeliv;
    }
    return null;
  }, [location, projectWritePlugin, projectReqToDeliv]);

  useEffect(() => {
    cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
    return () => {
      ApiService.cancelTokens(cancelTokenSourceRef.current);
    };
  }, []);
  useEffect(() => {
    if (isLogged && !user && domain) {
      setTimeout(() => {
        batch(() => {
          dispatch(getFlags(cancelTokenSourceRef.current.token));
          dispatch(getUser(cancelTokenSourceRef.current.token));
        });
      }, 300);
    }
  }, [isLogged, domain]);
  useEffect(() => {
    const cancelToken = ApiService.getCancelTokenSource();
    if (currentProject?.id && isLogged && company?.id) {
      dispatch(getSegFlags({ projectId: currentProject?.id, token: cancelToken.token }));
    }
    return () => {
      ApiService.cancelTokens(cancelToken);
    };
  }, [currentProject, company]);
  useEffect(() => {
    setIsInGlobalPath(PATHS_TO_DISABLE.includes(location?.pathname));
  }, [location]);
  const handleMenuExpand = () => {
    setMenuOpened(true);
  };
  const handleMenuClose = () => {
    setMenuOpened(false);
  };
  return (
    <>
      <div className={styles.container}>
        <img alt="Aitenders_Logo" className={styles.logo} height="20px" src={aitendersLogo} />
        <div className={styles.rightSide__container}>
          {!isInGlobalPath && (
            <div className={styles.projectName}>
              <span>{currentProject?.name}</span>
              {location.pathname === "/link-requirement-to-deliverable" && <span>{deliverableName}</span>}
            </div>
          )}
          <CustomIconButton
            ref={anchorRef}
            className={styles.button}
            color="secondary"
            icon={icon.faGear}
            size="sm"
            tooltip={translate("common:btn.parameters")}
            variant="outlined"
            onClick={handleMenuExpand}
          />
          <Menus ref={anchorRef} isInGlobalPath={isInGlobalPath} open={menuOpened} onClose={handleMenuClose} />
        </div>
      </div>
      {!isInGlobalPath && <SecondaryMenu />}
    </>
  );
};

export default UserBar;
