import React, { useEffect, useState } from "react";
import { KeycloakService } from "../../sevices";
import { isNonEmptyArray, parseJwt } from "../../common/utils";
import styles from "./InstanceSelection.module.css";
import { setCompany, setDeliverable, setDomain, setProject, setProjectReqToDeliv } from "../../common/slice";
import { useDispatch, useSelector } from "react-redux";
import { NavUtils } from "../../navigation";
import { useHistory, useLocation } from "react-router-dom";
import InstanceRow from "./common/InstanceRow";
import { CustomButton } from "../../common/components";
import { translate } from "../../common/providers";

const InstanceSelection = () => {
  const domain = useSelector(({ context }) => context.domain);
  const [instances, setInstances] = useState([]);
  const [selectedInstance, setSelectedInstance] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const groups = parseJwt(KeycloakService.getToken()).groups;
    if (!isNonEmptyArray(groups)) {
      KeycloakService.doLogout();
    } else {
      setInstances(groups);
    }
  }, []);
  useEffect(() => {
    if (isNonEmptyArray(instances) && instances.length === 1) {
      dispatch(setDomain(instances[0]));
      history.push(NavUtils.gotoCompanySelection());
    }
  }, [instances]);
  useEffect(() => {
    if (domain) {
      setSelectedInstance(domain);
      if (location.search !== "?manualModification=true") {
        history.push(NavUtils.gotoCompanySelection());
      }
    }
  }, [domain, location]);
  const handleSubmit = () => {
    dispatch(setDomain(selectedInstance));
    history.push(NavUtils.gotoCompanySelection());
  };
  const handleChange = (instanceSelection) => {
    setSelectedInstance(instanceSelection);
    dispatch(setProject({}));
    dispatch(setProjectReqToDeliv({}));
    dispatch(setDeliverable({}));
    dispatch(setCompany({}));
  };
  const handleLogout = () => {
    KeycloakService.doLogout();
  };
  return (
    <div className={styles.container}>
      <div className={styles.instanceSelectionContainer}>
        <div className={styles.helperText}>{translate("common:instance-selection")}</div>
        <div className={styles.InstanceList}>
          {isNonEmptyArray(instances) &&
            instances.map((instance) => (
              <InstanceRow
                key={instance}
                instance={instance}
                selectedInstance={selectedInstance}
                onClick={handleChange}
              />
            ))}
        </div>
      </div>
      <div className={styles.actionsContainer}>
        <CustomButton variant="outlined" onClick={handleLogout}>
          {translate("common:btn.back")}
        </CustomButton>
        <CustomButton disabled={!selectedInstance} variant="contained" onClick={handleSubmit}>
          {translate("common:btn.next")}
        </CustomButton>
      </div>
    </div>
  );
};

export default InstanceSelection;
