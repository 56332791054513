import client from "../client/client";

class LinkReqToDelivService {
  getProjects({ direction, limit = 50, page = 0, sort }, value, cancelToken) {
    return client.post("/spp/projects", {
      payload: value,
      params: { page, limit, sort, direction },
      config: { cancelToken },
    });
  }
  getDeliverables({ projectId }, { page = 0, limit = 50, filter }, cancelToken) {
    return client.get(`/spp/projects/${projectId}/documents-out`, {
      params: { page, limit, filter },
      config: { cancelToken },
    });
  }

  getRequirementsForDeliverables({ projectId, deliverableId }, { page = 0, limit = 50, isCovered }, cancelToken) {
    return client.get(`/spp/projects/${projectId}/documents-out/${deliverableId}/coverage`, {
      params: { page, limit, isCovered },
      config: { cancelToken },
    });
  }
  getRequirementsForDeliverablesCount({ projectId, deliverableId }, cancelToken) {
    return client.get(`/spp/projects/${projectId}/documents-out/${deliverableId}/coverage/count`, {
      config: { cancelToken },
    });
  }
  updateRequirementCoverage({ projectId, reqId, deliverableId }, { value }, cancelToken) {
    return client.post(`/spp/projects/${projectId}/requirements/${reqId}/document-out/${deliverableId}`, {
      payload: { value },
      config: { cancelToken },
    });
  }
}
export default new LinkReqToDelivService();
