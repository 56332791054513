/* eslint-disable office-addins/no-navigational-load */
/* global  Office, Word */

import React, { useEffect, useMemo, useState } from "react";
import { RequirementBox } from "../";
import { isNonEmptyArray } from "../../../../common/utils";
import styles from "./RequirementList.module.css";
import { useApi } from "../../../../common/hooks";
import { LinkReqToDelivService } from "../../../../api";
import { useSelector } from "react-redux";
import { TABS } from "../../constants/tabs";
import { translate } from "../../../../common/providers";
import { CustomButton } from "../../../../common/components";

const linkReqIdRegex = /Req:\s*(\d+)/;
const linkIdRegex = /linkId:\s*(\d+)/;

const RequirementList = () => {
  const { call: getRequirementsForDeliverables } = useApi(LinkReqToDelivService.getRequirementsForDeliverables);
  const [selectedLinkId, setSelectedLinkId] = useState(0);
  const [selectedReqId, setSelectedReqId] = useState(0);
  const deliverableId = useSelector(({ context }) => context.deliverable?.id);
  const projectId = useSelector(({ context }) => context.projectReqToDeliv?.id);
  const selectedTab = useSelector(
    ({ LinkRequirementToDeliverableSlice }) => LinkRequirementToDeliverableSlice.selectedTab
  );
  const [requirements, setRequirements] = useState(null);

  const [page, setPage] = useState(0);
  const isCovered = useMemo(() => {
    if (selectedTab === TABS.ALL) {
      return undefined;
    }
    if (selectedTab === TABS.COVERED) {
      return true;
    }
    if (selectedTab === TABS.TO_COVER) {
      return false;
    }
    return undefined;
  }, [selectedTab]);
  const remainingCount = useMemo(() => requirements?.totalElements - (page + 1) * 50, [requirements, page]);

  const handleSelectionChange = async () => {
    try {
      await Word.run(async (context) => {
        const selection = context.document.getSelection();
        selection.load("contentControls");
        await context.sync();
        const contentControls = selection.contentControls;
        contentControls.load("tag");
        await context.sync();
        if (contentControls.items.length > 0) {
          const contentControl = contentControls.items[0];
          setSelectedLinkId(parseInt(contentControl.tag?.match(linkIdRegex)?.[1]) || undefined);
          setSelectedReqId(parseInt(contentControl.tag?.match(linkReqIdRegex)?.[1]) || undefined);
        }
      });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    Office.onReady((info) => {
      if (info.host === Office.HostType.Word) {
        Office.context.document?.addHandlerAsync(Office.EventType.DocumentSelectionChanged, handleSelectionChange);
      }
    });
    return () => {
      Office.context.document?.removeHandlerAsync(Office.EventType.DocumentSelectionChanged, handleSelectionChange);
    };
  }, [Office.context.document]);

  useEffect(() => {
    getRequirementsForDeliverables({ projectId, deliverableId }, { page, limit: 50, isCovered })
      .then((data) => {
        if (page === 0) {
          setRequirements(data);
        } else {
          setRequirements((prev) => ({ ...data, contents: [...prev.contents, ...data.contents] }));
        }
      })
      .catch(console.error);
  }, [projectId, deliverableId, isCovered, page]);
  useEffect(() => {
    setPage(0);
  }, [selectedTab]);
  const handleIncrementPage = () => {
    if (remainingCount > 0) {
      setPage((prev) => prev + 1);
    }
  };
  const handleResetSelection = () => {
    setSelectedLinkId(0);
    setSelectedReqId(0);
  };

  return (
    <div className={styles.container}>
      <div className={styles.listContainer} data-has-more={remainingCount > 0}>
        {isNonEmptyArray(requirements?.contents) &&
          requirements?.contents.map((req) => (
            <RequirementBox
              key={req.id}
              row={req}
              selectedLinkId={selectedLinkId}
              selectedReqId={selectedReqId}
              onResetSelection={handleResetSelection}
            />
          ))}
      </div>
      {remainingCount > 0 && (
        <CustomButton className={styles.button} color="secondary" variant="outlined" onClick={handleIncrementPage}>
          {translate("requirements-to-deliverables.requirement-list.view-more", {
            count: remainingCount,
          })}
        </CustomButton>
      )}
    </div>
  );
};

export default RequirementList;
