import React, { useEffect, useState } from "react";
import {
  CustomIconButton,
  CustomTooltip,
  DocPreview,
  IconComponent,
  InfiniteList2,
  createNotification,
  generateFilters,
  icon,
} from "../../../../../../../common/components";
import { useApi } from "../../../../../../../common/hooks";
import { ContentService, DocumentService, SearchCardService, SimilarContentService } from "../../../../../../../api";
import { useSelector } from "react-redux";
import { translate } from "../../../../../../../common/providers";
import { EmptyState, IdentifiedContentBox } from "../../../..";
import { getPage, resetContent } from "../../../../../slice/multi-project-search-slice";
import styles from "./IdentifiedContents.module.css";
import { SEARCH_TABS } from "../../../../../constants/tabs";
import { isNonEmptyArray, WordUtils } from "../../../../../../../common/utils";

const IdentifiedContents = () => {
  const { call: getMostRelevantChaptersContents } = useApi(SimilarContentService.getMostRelevantChaptersContents);
  const { call: getMostRelevantContentInProject } = useApi(SearchCardService.search);
  const { call: saveContent } = useApi(ContentService.saveContent);
  const { call: onDownloadDocument } = useApi(DocumentService.downloadDocument);
  const filters = useSelector(({ multiProjectSearch }) => multiProjectSearch.filters);
  const projectFilters = useSelector(({ multiProjectSearch }) => multiProjectSearch.projectFilters);
  const selectedSearchTab = useSelector(({ multiProjectSearch }) => multiProjectSearch.selectedSearchTab);
  const projectId = useSelector(({ context }) => context.project?.id);
  const projectName = useSelector(({ context }) => context.project?.name);
  const [chaptersRequest, setChaptersRequest] = useState(null);
  const [projectRequest, setProjectRequest] = useState(null);
  const [resultCount, setResultCount] = useState(0);
  const [preview, setPreview] = useState(null);

  useEffect(() => {
    if (Object.keys(filters).some((item) => item !== "Type" && item !== "separator")) {
      if (filters.content) {
        setChaptersRequest(
          () =>
            ({ page, limit }) =>
              getMostRelevantChaptersContents(
                { page, limit },
                {
                  filters: { ...generateFilters(filters), input: filters.content, content: undefined },
                }
              )
        );
      }
    }
  }, [filters]);
  useEffect(() => {
    if (Object.keys(projectFilters).some((item) => item !== "Type" && item !== "separator")) {
      setProjectRequest(
        () =>
          ({ page, limit }) =>
            getMostRelevantContentInProject({ projectId, page, limit }, generateFilters(projectFilters))
      );
    }
  }, [projectFilters, projectId]);

  const handleCloseDisplay = () => setPreview(null);
  const handleDisplay = ({ projectId, documentId, informationId, previewResponse, identifiedContent }) => {
    setPreview({
      coordinates: previewResponse.rectangles,
      projectId: projectId,
      documentId,
      page: previewResponse.pageStart,
      numOfPages: previewResponse.numberOfPages,
      identifiedContent,
      informationId,
    });
  };
  const handleSaveContentFromList = ({ projectId, documentId, informationId, previewResponse }) => {
    const { numberOfPages, pageStart } = previewResponse;

    if (selectedSearchTab === SEARCH_TABS.DELIVERABLE) {
      saveContent({ informationId, documentId, numberOfPages, pageStart }, { projectId: undefined })
        .then(() => createNotification({ type: "info", message: translate("common:notification.selection-was-saved") }))
        .catch(console.error);
    } else {
      saveContent({ informationId, documentId, numberOfPages, pageStart }, { projectId })
        .then(() => createNotification({ type: "info", message: translate("common:notification.selection-was-saved") }))
        .catch(console.error);
    }
  };
  const handleSaveContent = ({ informationId, recievedRectangles }) => {
    const { documentId, projectId } = preview?.identifiedContent;
    const { numOfPages, page } = preview;
    let numberOfPages = numOfPages;
    let pageStart = page;
    if (isNonEmptyArray(recievedRectangles)) {
      numberOfPages = recievedRectangles.length;
      pageStart = recievedRectangles[0].pageNumber;
    }
    if (selectedSearchTab === SEARCH_TABS.DELIVERABLE) {
      saveContent(
        {
          informationId: isNonEmptyArray(recievedRectangles) ? undefined : informationId,
          contentPositionDTOs: isNonEmptyArray(recievedRectangles) ? recievedRectangles : undefined,
          documentId,
          numberOfPages,
          pageStart,
        },
        { projectId: undefined }
      )
        .then(() => createNotification({ type: "info", message: translate("common:notification.selection-was-saved") }))
        .catch(console.error);
    } else {
      saveContent(
        {
          informationId: isNonEmptyArray(recievedRectangles) ? undefined : informationId,
          contentPositionDTOs: isNonEmptyArray(recievedRectangles) ? recievedRectangles : undefined,
          documentId,
          numberOfPages,
          pageStart,
        },
        { projectId: projectId }
      )
        .then(() => createNotification({ type: "info", message: translate("common:notification.selection-was-saved") }))
        .catch(console.error);
    }
  };

  const handleResponse = (data) => {
    setResultCount(data.totalElements);
  };

  const rowRenderer = (identifiedContent) => {
    return (
      <IdentifiedContentBox
        key={identifiedContent.informationId}
        identifiedContent={identifiedContent}
        onDisplay={handleDisplay}
        onSaveContent={handleSaveContentFromList}
      />
    );
  };
  const emptyState = () => {
    return <EmptyState />;
  };
  const handleOpenNewWord = () => {
    onDownloadDocument({ docId: preview?.identifiedContent?.documentId })
      .then(({ data, filename }) => WordUtils.openDocumentInNewFile({ data, filename }))
      .catch((err) => console.error(err));
  };
  const innerActions = ({ disableActions, renderCondition, onSaveContent, onInsert, onResetRecievedRectangles }) => (
    <div className={styles.innerActionsContainer}>
      <div className={styles.innerActionsNames}>
        <CustomTooltip showWhenOverflow title={projectName}>
          <span className={styles.projectName}>{projectName}</span>
        </CustomTooltip>
        <CustomTooltip showWhenOverflow title={preview?.identifiedContent?.documentName}>
          <span className={styles.documentName} role="presentation" onClick={handleOpenNewWord}>
            {preview?.identifiedContent?.documentName}
          </span>
        </CustomTooltip>
      </div>
      <div className={styles.innerActions__buttonContainer}>
        <CustomIconButton
          disabled={disableActions}
          icon={icon.faArrowLeft}
          size="sm"
          tooltip={
            renderCondition
              ? translate("common:doc-preview.insert-selection")
              : translate("common:doc-preview.insert-chapter")
          }
          variant="outlined"
          onClick={onInsert}
        />
        <CustomIconButton
          disabled={disableActions}
          icon={icon.faBookmarkRegular}
          size="sm"
          tooltip={translate("common:btn.save")}
          variant="outlined"
          onClick={onSaveContent}
        />
        {renderCondition && (
          <CustomIconButton
            icon={icon.faBan}
            size="sm"
            tooltip={translate("common:doc-preview.cancel-selection")}
            variant="outlined"
            onClick={onResetRecievedRectangles}
          />
        )}
      </div>
    </div>
  );

  return (
    <>
      {resultCount > 0 && (
        <div className={styles.resultCount}>
          <div>
            {translate(resultCount === 1 ? "common:empty-state.x-result" : "common:empty-state.x-results", {
              count: resultCount,
            })}
          </div>
          <div>
            <IconComponent
              className={styles.sortIcon}
              color="var(--color-dark-grey-1)"
              icon={icon.faArrowDownWideShort}
            />
            {translate("common:sort.precedence")}
          </div>
        </div>
      )}
      <InfiniteList2
        callOnResponse={handleResponse}
        className={styles.list__container}
        noRowsRenderer={emptyState}
        request={selectedSearchTab === SEARCH_TABS.PROJECT ? projectRequest : chaptersRequest}
        resetData={selectedSearchTab}
        rowRenderer={rowRenderer}
      />
      <DocPreview
        coordinates={preview?.coordinates}
        docId={preview?.documentId}
        infoId={preview?.informationId}
        innerActions={innerActions}
        numOfPages={preview?.numOfPages}
        open={!!preview}
        page={preview?.page}
        projectId={preview?.projectId}
        slice="multiProjectSearch"
        onClose={handleCloseDisplay}
        onGetPage={getPage}
        onResetContent={resetContent}
        onSaveContent={handleSaveContent}
      />
    </>
  );
};

export default IdentifiedContents;
