import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styles from "./DocByFolder.module.css";
import { Item } from "..";
import { ApiService, ProjectDocumentsService } from "../../../../../../../api";
import { FILE_TYPES } from "../../../../../../constants/file-types";
import { CustomBreadcrumb, InfiniteList2 } from "../../../../..";

export default function DocByFolder({ externalSearch, onDocumentSelection, onSetSelectedDocuments, selection = [] }) {
  const [infiniteListRequest, setInfiniteListRequest] = useState(null);
  const [selectedDirectory, setSelectedDirectory] = useState("root");
  const [locations, setLocations] = useState([{ id: "root" }]);
  const cancelTokenSourceRef = useRef(null);
  const currentDocDetails = useSelector(({ srProjectDocument }) => srProjectDocument?.currentDocDetails);
  const project = useSelector(({ context }) => context.project);
  useEffect(() => {
    cancelTokenSourceRef.current = ApiService.getCancelTokenSource();
    setInfiniteListRequest(
      () =>
        ({ page, limit }) =>
          ProjectDocumentsService.getDirectory(
            { projectId: project.id, directoryId: selectedDirectory },
            { page, limit, search: externalSearch, excludeDeliverable: true },
            cancelTokenSourceRef.current.token
          )
    );
    return () => {
      ApiService.cancelTokens(cancelTokenSourceRef.current);
    };
  }, [project.id, locations, externalSearch, selectedDirectory]);
  const handleClick = (_, item) => {
    if (
      item?.type === FILE_TYPES.FILE &&
      item.id !== currentDocDetails?.documentId &&
      typeof onDocumentSelection === "function"
    ) {
      onDocumentSelection({ id: item.id, documentName: item.name, forced: true, ...item });
    }
    if ((!item || item.type === FILE_TYPES.FOLDER) && item?.id) {
      setSelectedDirectory(item.id);
      setLocations((prev) => [...prev, item]);
    }
  };
  const handleSkipLonelyFolders = (data) => {
    const { contents } = data;
    if (Array.isArray(contents) && contents.length === 1 && contents[0].type === FILE_TYPES.FOLDER) {
      handleClick(contents[0]);
    }
  };
  const handleOptionSelect = (value) => {
    const tempLocations = [...locations];
    tempLocations.length = locations.map((location) => location.id).indexOf(value.id) + 1;
    setSelectedDirectory(value.id);
    setLocations(tempLocations);
  };
  const rowRenderer = (opt) => {
    if ((onSetSelectedDocuments && !selection.some((sel) => sel === opt.id)) || !onSetSelectedDocuments) {
      return (
        <Item
          key={opt.label}
          folder
          iconName={opt.type === FILE_TYPES.FOLDER ? "faFolder" : "faFilePdf"}
          item={opt}
          label={opt.name || opt.label}
          next={opt.type === FILE_TYPES.FOLDER}
          selected={selection.some((sel) => sel === opt.id)}
          onClickItem={(e) => handleClick(e, opt)}
        />
      );
    }
    return null;
  };
  return (
    <>
      <CustomBreadcrumb
        breadcrumbClassName={styles.breadcrumb__content}
        className={styles.breadcrumb}
        iconName="faFolder"
        locations={locations}
        onOptionSelection={handleOptionSelect}
      />
      <div className={styles.main}>
        <InfiniteList2
          callOnResponse={handleSkipLonelyFolders}
          className={styles.listContainer}
          request={infiniteListRequest}
          rowRenderer={rowRenderer}
        />
      </div>
    </>
  );
}
