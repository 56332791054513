import { isNonEmptyArray, isNonEmptyObject } from "../../../utils";

export const generateFilters = (newFilters) => {
  if (!newFilters) {
    return;
  }
  const {
    infoversion,
    categories,
    reqCriticality,
    reqNegotiability,
    reqType,
    requirement,
    clientRef,
    documents,
    granularity,
    ...rest
  } = newFilters;
  const newPayload = {};
  if (isNonEmptyArray(requirement)) {
    if ((requirement || []).includes("isRequirement")) {
      newPayload.isRequirement = true;
    }
    if ((requirement || []).includes("isClientRequirement")) {
      newPayload.isClientRequirement = true;
    }
  } else if (isNonEmptyObject(requirement) && requirement?.elements) {
    const { is, elements = [] } = requirement;
    const finalIs = is === undefined ? true : is;
    if (Array.isArray(elements)) {
      if (elements.includes("isRequirement")) {
        newPayload.isRequirement = finalIs;
      }
      if (elements.includes("isClientRequirement")) {
        newPayload.isClientRequirement = finalIs;
      }
    }
    Object.values(requirement).forEach((value) => {
      if (value === "isRequirement") {
        newPayload.isRequirement = is;
      }
      if (value === "isClientRequirement") {
        newPayload.isClientRequirement = is;
      }
    });
  }
  if (reqCriticality) {
    newPayload.reqCriticality = { is: true, matchAll: true, elements: reqCriticality };
  }
  if (reqNegotiability) {
    newPayload.reqNegotiability = { is: true, matchAll: true, elements: reqNegotiability };
  }
  if (reqType) {
    newPayload.reqType = { is: true, matchAll: true, elements: reqType };
  }
  if (infoversion) {
    newPayload.versionStatus = infoversion;
  }
  if (isNonEmptyArray(documents?.elements)) {
    newPayload.documents = documents;
  }
  if (categories?.FAVORITE?.length > 0) {
    newPayload.favoriteCategoryIds = categories.FAVORITE;
  }
  if (clientRef?.length > 0) {
    newPayload.clientRef = clientRef;
  }
  if (categories?.CATEGORY?.length > 0) {
    newPayload.categories = {
      is: categories.is === undefined ? true : categories.is,
      matchAll: categories.matchAll === undefined ? false : categories.matchAll,
      elements: categories.CATEGORY,
    };
  }
  if (isNonEmptyArray(granularity)) {
    newPayload.isInChapter = granularity?.includes("chapter") ? true : undefined;
    newPayload.isInContent = granularity?.includes("content") ? true : undefined;
  }
  return {
    ...newPayload,
    ...rest,
  };
};
export const adjustFiltersFromPayload = (newFilters) => {
  if (!newFilters) {
    return;
  }
  const {
    isRequirement,
    isClientRequirement,
    reqCriticality,
    reqNegotiability,
    reqType,
    versionStatus,
    favoriteCategoryIds,
    categories,
    isInChapter,
    isInContent,
    ...rest
  } = newFilters;
  const newPayload = {};
  const requirement = {
    is: null,
    elements: [],
  };
  let granularity = [];
  if ("isRequirement" in newFilters) {
    requirement.is = requirement.is === null ? !!isRequirement : requirement.is;
    requirement.elements.push("isRequirement");
    newPayload.requirement = requirement;
  }
  if ("isClientRequirement" in newFilters) {
    requirement.is = requirement.is === null ? !!isClientRequirement : requirement.is;
    requirement.elements.push("isClientRequirement");
    newPayload.requirement = requirement;
  }
  if (reqCriticality) {
    newPayload.reqCriticality = reqCriticality.elements;
  }
  if (isInChapter) {
    granularity = [...granularity, "chapter"];
    newPayload.granularity = granularity;
  }
  if (isInContent) {
    granularity = [...granularity, "content"];
    newPayload.granularity = granularity;
  }
  if (reqNegotiability) {
    newPayload.reqNegotiability = reqNegotiability.elements;
  }
  if (reqType) {
    newPayload.reqType = reqType.elements;
  }
  if (versionStatus) {
    newPayload.infoversion = versionStatus;
  }
  if (favoriteCategoryIds) {
    newPayload.categories.FAVORITE = favoriteCategoryIds;
    if (!isNonEmptyArray(categories?.elements)) {
      newPayload.categories = { matchAll: categories.matchAll, is: categories.is, CATEGORY: [] };
    }
  }
  if (categories?.elements?.length > 0) {
    newPayload.categories = { matchAll: categories.matchAll, is: categories.is, CATEGORY: categories.elements };
    if (!isNonEmptyArray(favoriteCategoryIds)) {
      newPayload.categories.FAVORITE = [];
    }
  }
  return {
    ...newPayload,
    ...rest,
  };
};

export const getActiveFiltersCount = (filters) => {
  let count = 0;
  const keys = Object.keys(filters);
  keys.forEach((key) => {
    if (key !== "separator" && key !== "Type" && key !== "content" && key !== "input") {
      if (typeof filters[key] === "string" && filters[key]?.length > 0) {
        count += 1;
      }
      if (key === "keywordFilters" || key === "tocFilters") {
        count += filters[key]?.filters?.length - 1;
      }
      if (isNonEmptyArray(filters[key])) {
        count += 1;
      }
      if (isNonEmptyObject(filters[key])) {
        if (
          isNonEmptyArray(filters[key].elements) ||
          isNonEmptyObject(filters[key].filters) ||
          isNonEmptyArray(filters[key].CATEGORY)
        ) {
          count += 1;
        }
      }
    }
  });
  return count;
};
