import React, { useCallback, useEffect, useState } from "react";
import {
  CircularLoader,
  CustomButton,
  CustomIconButton,
  GenericDialog,
  GenericDialogContent,
  GenericDialogHeader,
  I18nTranslate,
  icon,
} from "../../../../../common/components";
import { useDispatch, useSelector } from "react-redux";
import ReactDOMServer from "react-dom/server";
import { translate } from "../../../../../common/providers";
import styles from "./SyncDialog.module.css";
import { isNonEmptyArray, WordUtils } from "../../../../../common/utils";
import { setDeliverableToUpdate } from "../../../../../common/slice";
import { ParagraphOutService } from "../../../../../api";
import { useApi } from "../../../../../common/hooks";
import { setUpdateReqList } from "../../../../../views/link-requirement-to-deliverable/slice/link-requirement-to-deliverable-slice";

const SyncDialog = ({ onClose, open }) => {
  const { call: getCountLinksNotPresentInDoc } = useApi(ParagraphOutService.getCountLinksNotPresentInDoc);
  const { call: deleteLinksNotPresentInDoc } = useApi(ParagraphOutService.deleteLinksNotPresentInDoc);
  const { call: transferLinksAndParagraphsToNewDeliv } = useApi(
    ParagraphOutService.transferLinksAndParagraphsToNewDeliv
  );
  const deliverableName = useSelector(({ context }) => context.deliverable?.name);
  const deliverableId = useSelector(({ context }) => context.deliverable?.id);
  const projectId = useSelector(({ context }) => context.projectReqToDeliv?.id);
  const [totalCount, setTotalCount] = useState(0);
  const [doNotReferToDelivCount, setDoNotReferToDelivCount] = useState(0);
  const [doNotReferinAITCount, setDoNotReferinAITCount] = useState(0);
  const [actionInProgress, setActionInprogress] = useState(false);
  const [loadingCounters, setLoadingCounters] = useState(true);
  const [linksMatchedDeliv, setLinksMatchedDeliv] = useState([]);
  const [linksMissMatchedDeliv, setLinksMissMatchedDeliv] = useState([]);
  const [currentGlobalIndex, setCurrentGlobalIndex] = useState(0);
  const [currentMissmatchIndex, setCurrentMissmatchIndex] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    setCurrentGlobalIndex(0);
    setCurrentMissmatchIndex(0);
  }, [open]);

  const handleCounters = useCallback(
    ({ total, matchDelivId, missMatchDelivId }) => {
      setTotalCount(total);
      setDoNotReferToDelivCount(total - matchDelivId.length);
      setLinksMatchedDeliv(matchDelivId);
      setLinksMissMatchedDeliv(missMatchDelivId);
      getCountLinksNotPresentInDoc(
        { projectId, deliverableId },
        { values: isNonEmptyArray(matchDelivId) ? matchDelivId : null }
      )
        .then((data) => setDoNotReferinAITCount(data))
        .catch(console.error)
        .finally(() => setLoadingCounters(false));
    },
    [projectId, deliverableId]
  );
  useEffect(() => {
    if (open) {
      setLoadingCounters(true);
      WordUtils.findContentControls(deliverableId, handleCounters);
    }
  }, [open, handleCounters]);

  const handleClose = () => {
    onClose();
    setActionInprogress(false);
  };
  const handleChangeDeliverable = () => {
    dispatch(setDeliverableToUpdate(true));
    handleClose();
  };
  const handleRemoveFromDoc = () => {
    setActionInprogress(true);
    WordUtils.removeFromDocNotCorrectDeliv(deliverableId, handleClose);
  };
  const handleDeleteFromAIT = () => {
    setActionInprogress(true);
    deleteLinksNotPresentInDoc(
      { projectId, deliverableId },
      { values: isNonEmptyArray(linksMatchedDeliv) ? linksMatchedDeliv : [] }
    )
      .catch(console.error)
      .finally(() => {
        handleClose();
        dispatch(setUpdateReqList(true));
      });
  };
  const handleCreateCopyInAIT = () => {
    setActionInprogress(true);
    transferLinksAndParagraphsToNewDeliv({ deliverableId, projectId }, { linksIds: linksMissMatchedDeliv })
      .then((data) => WordUtils.updateContentControlsAfterMigration(data, deliverableId))
      .catch(console.error)
      .finally(() => {
        handleClose();
        dispatch(setUpdateReqList(true));
      });
  };
  const handleIncrementGlobalIndex = () => {
    setCurrentGlobalIndex((prev) => prev + 1);
    WordUtils.highlightGlobalContentAt(currentGlobalIndex + 1);
  };
  const handleDecrementGlobalIndex = () => {
    setCurrentGlobalIndex((prev) => prev - 1);
    WordUtils.highlightGlobalContentAt(currentGlobalIndex - 1);
  };
  const handleIncrementMissmatchIndex = () => {
    setCurrentMissmatchIndex((prev) => prev + 1);
    WordUtils.highlightMissMatchedContentAt(currentMissmatchIndex + 1, deliverableId);
  };
  const handleDecrementMissMatchIndex = () => {
    setCurrentMissmatchIndex((prev) => prev - 1);
    WordUtils.highlightMissMatchedContentAt(currentMissmatchIndex - 1, deliverableId);
  };
  return (
    <GenericDialog open={open} onClose={handleClose}>
      <GenericDialogHeader className={styles.header}>
        <CustomIconButton icon={icon.faTimes} onClick={handleClose} />
      </GenericDialogHeader>
      <GenericDialogContent>
        <div className={styles.container}>
          <span>
            <span>{translate("common:sync-dialog.document-contain-links.part1")}</span>
            <CustomIconButton
              className={styles.navigateButton}
              disabled={loadingCounters || currentGlobalIndex <= 1}
              icon={icon.faChevronLeft}
              size="xs"
              onClick={handleDecrementGlobalIndex}
            />
            <b>
              {currentGlobalIndex}/{loadingCounters ? <CircularLoader size={14} /> : totalCount}
            </b>
            <CustomIconButton
              className={styles.navigateButton}
              disabled={loadingCounters || currentGlobalIndex >= totalCount}
              icon={icon.faChevronRight}
              size="xs"
              onClick={handleIncrementGlobalIndex}
            />
            <span>{translate("common:sync-dialog.document-contain-links.part2")}</span>
          </span>
          <span>
            <CustomIconButton
              className={styles.navigateButton}
              disabled={loadingCounters || currentMissmatchIndex <= 1}
              icon={icon.faChevronLeft}
              size="xs"
              onClick={handleDecrementMissMatchIndex}
            />
            <b>
              {currentMissmatchIndex}/{loadingCounters ? <CircularLoader size={14} /> : doNotReferToDelivCount}
            </b>
            <CustomIconButton
              className={styles.navigateButton}
              disabled={loadingCounters || currentMissmatchIndex >= doNotReferToDelivCount}
              icon={icon.faChevronRight}
              size="xs"
              onClick={handleIncrementMissmatchIndex}
            />
            <I18nTranslate
              param={{
                deliverableName,
              }}
              translationKey="common:sync-dialog.links-do-not-refer-to-document"
            />
          </span>
          <div className={styles.btnContainer}>
            <CustomButton
              disabled={actionInProgress || loadingCounters}
              size="md"
              variant="contained"
              onClick={handleCreateCopyInAIT}
            >
              {translate("common:sync-dialog.btn.create-links-ait-db", { deliverableName })}
            </CustomButton>
            <CustomButton
              disabled={actionInProgress || loadingCounters}
              size="md"
              variant="contained"
              onClick={handleRemoveFromDoc}
            >
              {actionInProgress && <CircularLoader size={16} />}
              {translate("common:sync-dialog.btn.delete-links-doc")}
            </CustomButton>
            <CustomButton
              disabled={actionInProgress || loadingCounters}
              size="md"
              variant="contained"
              onClick={handleChangeDeliverable}
            >
              {actionInProgress && <CircularLoader size={16} />}
              {translate("common:sync-dialog.btn.change-deliverable-ait")}
            </CustomButton>
          </div>
          <I18nTranslate
            param={{
              count: loadingCounters
                ? ReactDOMServer.renderToStaticMarkup(<CircularLoader size={14} />)
                : doNotReferinAITCount,
              deliverableName,
              loadingCounters,
            }}
            translationKey="common:sync-dialog.links-do-not-refer-to-db"
          />
          <CustomButton
            disabled={actionInProgress || loadingCounters}
            size="md"
            variant="contained"
            onClick={handleDeleteFromAIT}
          >
            {translate("common:sync-dialog.btn.delete-links-ait")}
          </CustomButton>
        </div>
      </GenericDialogContent>
    </GenericDialog>
  );
};

export default SyncDialog;
