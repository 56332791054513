import React, { useState } from "react";
import { CustomIconButton, icon } from "../../../common/components";
import styles from "./SecondaryMenu.module.css";
import { MenuSidePanel } from "./components";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { translate } from "../../../common/providers";
const SecondaryMenu = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const handleOpen = () => {
    setOpen((prev) => !prev);
  };
  return (
    <>
      <div className={styles.secondary__container}>
        <CustomIconButton icon={icon.faBars} size="sm" onClick={handleOpen} />
        <span className={styles.currentMenu}>
          {translate(`common:user-bar.secondary-menu.${location.pathname.substring(1)}`)}
        </span>
      </div>
      <MenuSidePanel open={open} onClose={handleOpen} />
    </>
  );
};

export default SecondaryMenu;
