import React, { useEffect, useMemo, useState } from "react";
import { DropDown, icon, MenuElement } from "../../../common/components";
import { MenuList } from "@mui/material";
import { isNonEmptyArray, parseJwt } from "../../../common/utils";
import styles from "./Menus.module.css";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { KeycloakService } from "../../../sevices";
import { NavUtils } from "../../../navigation";
import { useDispatch, useSelector } from "react-redux";
import { ApiService } from "../../../api";
import {
  setDeliverableToUpdate,
  setFirstTimeLogin,
  setLanguage,
  setOpenProjectSelectionSidePanel,
  setProjectReqToDelivToUpdate,
} from "../../../common/slice";
import { translate } from "../../../common/providers";
import { setTourEnabled } from "../../../views/multi-project-search/slice/multi-project-search-slice";

const Menus = React.forwardRef(({ isInGlobalPath, onClose, open }, ref) => {
  const language = useSelector(({ context }) => context.language);
  const companies = useSelector(({ context }) => context.companies);
  const projects = useSelector(({ context }) => context.projects);
  const projectId = useSelector(({ context }) => context.project?.id);
  const companyId = useSelector(({ context }) => context.company?.id);
  const domain = useSelector(({ context }) => context.domain);
  const [openedSubMenu, setOpenedSubMenu] = useState("");
  const [hasSingleInstance, setHasSingleInstance] = useState();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    setHasSingleInstance(parseJwt(KeycloakService.getToken()).groups.length === 1);
  }, []);
  /// Menu and it's logic
  const handleOpenMenu = (subMenu) => {
    setOpenedSubMenu(subMenu);
  };
  const handleCloseSubMenu = () => {
    setOpenedSubMenu("");
  };
  const handleClose = () => {
    handleCloseSubMenu();
    onClose();
  };
  const selectLanguage = (value) => {
    const cancelToken = ApiService.getCancelTokenSource();
    setTimeout(() => {
      dispatch(setLanguage({ value, cancelToken }));
      dispatch(setFirstTimeLogin(false));
    }, 300);
  };
  const handleChangeLanguage = (e, value) => {
    e.stopPropagation();
    if (language.value === value) {
      handleClose();
      return;
    }
    selectLanguage(value);
    handleClose();
  };

  const handleChangeBU = () => {
    history.push(NavUtils.gotoCompanySelection(true));
    handleClose();
  };
  const handleChangeProject = () => {
    if (location.pathname === "/multi-project-search") {
      dispatch(setOpenProjectSelectionSidePanel(true));
    }
    if (location.pathname === "/link-requirement-to-deliverable") {
      dispatch(setProjectReqToDelivToUpdate(true));
    }
  };
  const handleChangeDeliverable = () => {
    dispatch(setDeliverableToUpdate(true));
  };
  const handleChangeInstance = () => {
    history.push(NavUtils.gotoHome(true));
    handleClose();
  };
  const handleRedirectNewTab = (e, link) => {
    e.stopPropagation();
    window.open(link, "_blank");
    handleClose();
  };
  const handleLogout = () => {
    KeycloakService.doLogout();
    handleClose();
  };
  const handleSelectHelpCenter = (e) => {
    e.stopPropagation();
    const hubspotWidget = window.HubSpotConversations.widget;
    hubspotWidget.open();
    handleClose();
  };
  const openMultiSearchTour = (e) => {
    e.stopPropagation();
    handleClose();
    dispatch(setTourEnabled(true));
  };
  const menus = useMemo(
    () => [
      {
        value: "language",
        title: translate("common:user-bar.change-language"),
        action: () => handleOpenMenu("language"),
        subMenus: [
          { value: "en", title: "English", action: (e) => handleChangeLanguage(e, "en") },
          { value: "esp", title: "Español", action: (e) => handleChangeLanguage(e, "esp") },
          { value: "fr", title: "Français", action: (e) => handleChangeLanguage(e, "fr") },
        ],
      },
      {
        title: translate("common:user-bar.change-instance"),
        hidden: hasSingleInstance,
        action: handleChangeInstance,
      },
      { title: translate("common:user-bar.change-company"), hidden: companies?.length <= 1, action: handleChangeBU },
      {
        title: translate("common:user-bar.change-project"),
        hidden: isInGlobalPath,
        action: handleChangeProject,
      },
      {
        title: translate("common:user-bar.change-deliverable"),
        hidden: location.pathname !== "/link-requirement-to-deliverable",
        action: handleChangeDeliverable,
      },
      {
        title: translate("common:user-bar.access-projects"),
        action: (e) => handleRedirectNewTab(e, `https://${domain}?companyId=${companyId}`),
        icon: icon.faArrowUpRightFromSquare,
      },

      {
        title: translate("common:user-bar.import-deliverable"),
        action: (e) =>
          handleRedirectNewTab(
            e,
            projectId
              ? `https://${domain}/projects/${projectId}/documents?companyId=${companyId}`
              : `https://${domain}?companyId=${companyId}`
          ),
        icon: icon.faArrowUpRightFromSquare,
      },
      {
        value: "help",
        action: () => handleOpenMenu("help"),
        title: translate("common:user-bar.help"),
        subMenus: [
          {
            title: translate("common:user-bar.aitenders-academy"),
            action: (e) => handleRedirectNewTab(e, "https://academy.aitenders.com/"),
            icon: icon.faGraduationCap,
          },
          { title: translate("common:user-bar.get-help"), icon: icon.faComments, action: handleSelectHelpCenter },
          { title: translate("common:user-bar.product-tour"), isHeader: true },
          { title: translate("common:user-bar.project list"), disabled: true, icon: icon.faCirclePlayRegular },
          { title: translate("common:user-bar.document-management"), disabled: true, icon: icon.faCirclePlayRegular },
          { title: translate("common:user-bar.search"), disabled: true, icon: icon.faCirclePlayRegular },
          { title: translate("common:user-bar.review"), disabled: true, icon: icon.faCirclePlayRegular },
          {
            title: translate("common:user-bar.word-complement-presentation"),
            action: openMultiSearchTour,
            icon: icon.faCirclePlayRegular,
            hidden: location.pathname !== "/multi-project-search",
          },
        ],
      },
      { title: translate("common:user-bar.logout"), action: handleLogout },
    ],
    [hasSingleInstance, companies, projects, projectId, location, isInGlobalPath]
  );
  ///
  return (
    <DropDown ref={ref} open={open} onClose={handleClose}>
      <MenuList className={styles.menuContainer}>
        {isNonEmptyArray(menus) &&
          menus
            .filter((menu) => !menu.hidden)
            .map((menu) => (
              <MenuElement
                key={menu.title}
                menu={menu}
                openedSubMenu={openedSubMenu}
                onCloseSubMenu={handleCloseSubMenu}
              />
            ))}
      </MenuList>
    </DropDown>
  );
});

export default Menus;
