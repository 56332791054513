import React, { useMemo } from "react";
import { NavUtils, exportView } from "../../navigation";
import { Row } from "./components";
import { isNonEmptyArray } from "../../common/utils";
import styles from "./MainMenu.module.css";
import { translate } from "../../common/providers";

const MainMenu = () => {
  const menus = useMemo(
    () => [
      {
        title: translate("main-menu.find-inspiration-title"),
        isBeta: true,
        subText: translate("main-menu.find-inspiration-sub-text"),
        path: NavUtils.gotoMultiProjectSearch(),
      },
      {
        title: translate("main-menu.req-to-deliv-title"),
        isBeta: true,
        subText: translate("main-menu.req-to-deliv-sub-text"),
        path: NavUtils.gotoLinkReqToDev(),
      },
    ],
    []
  );
  return (
    <div className={styles.container}>
      {isNonEmptyArray(menus) && menus.map((menu) => <Row key={menu.title} menu={menu} />)}
    </div>
  );
};

export default exportView({
  path: "/main-menu",
  component: MainMenu,
  localesPath: "/main-menu/locales",
});
