import React, { useEffect, useMemo, useState } from "react";
import { exportView } from "../../navigation";
import { ViewTabs } from "../../common/components";
import { Flags, translate } from "../../common/providers";
import { TABS } from "./constants/tabs";
import styles from "./LinkRequirementToDeliverable.module.css";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedTab, setUpdateReqList } from "./slice/link-requirement-to-deliverable-slice";
import { DeliverableSelection, ProjectSelection, RequirementList } from "./components";
import { useApi } from "../../common/hooks";
import { LinkReqToDelivService } from "../../api";

const LinkRequirementToDeliverable = () => {
  const { call: getRequirementsForDeliverablesCount } = useApi(
    LinkReqToDelivService.getRequirementsForDeliverablesCount
  );
  const selectedTab = useSelector(
    ({ LinkRequirementToDeliverableSlice }) => LinkRequirementToDeliverableSlice.selectedTab
  );
  const deliverable = useSelector(({ context }) => context.deliverable);
  const projectReqToDeliv = useSelector(({ context }) => context.projectReqToDeliv);
  const projectReqToDelivToUpdate = useSelector(({ context }) => context.projectReqToDelivToUpdate);
  const deliverableToUpdate = useSelector(({ context }) => context.deliverableToUpdate);
  const [counts, setCounts] = useState({ covered: 0, notCovered: 0 });
  const tabHeaders = useMemo(
    () => [
      {
        id: TABS.ALL,
        label: translate("requirements-to-deliverables.tabs.all"),
        tab: "AllTab",
      },
      {
        id: TABS.TO_COVER,
        label: translate("requirements-to-deliverables.tabs.to-cover", { count: counts?.notCovered }),
        tab: "ToCoverTab",
        disabled: !counts?.notCovered,
      },
      {
        id: TABS.COVERED,
        label: translate("requirements-to-deliverables.tabs.covered", { count: counts?.covered }),
        tab: "CoveredTab",
        disabled: !counts?.covered,
      },
    ],
    [counts]
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (projectReqToDeliv?.id && deliverable?.id) {
      getRequirementsForDeliverablesCount({ projectId: projectReqToDeliv?.id, deliverableId: deliverable?.id })
        .then((data) => {
          const tempCounts = { ...counts };
          data.forEach((d) => (tempCounts[d.content] = d.count));
          setCounts(tempCounts);
        })
        .catch(console.error);
    }
  }, [projectReqToDeliv, deliverable]);

  const handleChangeTab = (_, tab) => {
    dispatch(setSelectedTab(tab));
    dispatch(setUpdateReqList(true));
  };
  const handleUpdateCount = (value) => {
    if (value) {
      setCounts((prev) => ({ ...prev, covered: prev.covered + 1, notCovered: prev.notCovered - 1 }));
    } else {
      setCounts((prev) => ({ ...prev, covered: prev.covered - 1, notCovered: prev.notCovered + 1 }));
    }
  };
  const bodyRenderer = () => {
    if (!projectReqToDeliv.id || projectReqToDelivToUpdate) {
      return <ProjectSelection />;
    }
    if (!deliverable.id || deliverableToUpdate) {
      return <DeliverableSelection />;
    }
    return (
      <>
        <ViewTabs
          className={styles.tabs}
          selectedTab={selectedTab}
          tabIdentifier="id"
          tabs={tabHeaders}
          onChange={handleChangeTab}
        />
        <RequirementList onUpdateCount={handleUpdateCount} />
      </>
    );
  };
  return <div className={styles.container}>{bodyRenderer()}</div>;
};
export { default as LinkRequirementToDeliverableSlice } from "./slice/link-requirement-to-deliverable-slice";
export default exportView({
  path: "/link-requirement-to-deliverable",
  localesPath: "/link-requirement-to-deliverable/locales",
  flag: Flags.COVERAGE_PLUGIN,
  component: LinkRequirementToDeliverable,
});
