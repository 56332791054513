/* global  Office */

import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { RequirementBox, RequirementSidePanel } from "../";
import { isNonEmptyArray, WordUtils } from "../../../../common/utils";
import styles from "./RequirementList.module.css";
import { useApi } from "../../../../common/hooks";
import { LinkReqToDelivService, ParagraphOutService, RequirementMatrixService } from "../../../../api";
import { useDispatch, useSelector } from "react-redux";
import { TABS } from "../../constants/tabs";
import { translate } from "../../../../common/providers";
import { CustomButton, CustomButtonLink } from "../../../../common/components";
import { setUpdateReqList } from "../../slice/link-requirement-to-deliverable-slice";

const RequirementList = ({ onUpdateCount }) => {
  const updateReqList = useSelector(
    ({ LinkRequirementToDeliverableSlice }) => LinkRequirementToDeliverableSlice.updateReqList
  );
  const { call: getRequirementsForDeliverables } = useApi(LinkReqToDelivService.getRequirementsForDeliverables);
  const { call: updateTitleForParagraphs } = useApi(ParagraphOutService.updateTitleForParagraphs);
  const { call: updateParagraph } = useApi(ParagraphOutService.updateParagraph);
  const { call: updateRequirementCoverage } = useApi(LinkReqToDelivService.updateRequirementCoverage);
  const { call: getRequirementInformation } = useApi(RequirementMatrixService.getRequirementInformation);
  const [selectedLinkId, setSelectedLinkId] = useState(0);
  const [selectedReqId, setSelectedReqId] = useState(0);
  const [selectedReqDisplay, setSelectedReqDisplay] = useState({});
  const [page, setPage] = useState(0);
  const [requirements, setRequirements] = useState(null);
  const deliverableId = useSelector(({ context }) => context.deliverable?.id);
  const projectId = useSelector(({ context }) => context.projectReqToDeliv?.id);
  const domain = useSelector(({ context }) => context.domain);
  const companyId = useSelector(({ context }) => context.company?.id);
  const selectedTab = useSelector(
    ({ LinkRequirementToDeliverableSlice }) => LinkRequirementToDeliverableSlice.selectedTab
  );
  const sidePanelRef = useRef();
  const dispatch = useDispatch();

  const isCovered = useMemo(() => {
    if (selectedTab === TABS.ALL) {
      return undefined;
    }
    if (selectedTab === TABS.COVERED) {
      return true;
    }
    if (selectedTab === TABS.TO_COVER) {
      return false;
    }
    return undefined;
  }, [selectedTab]);
  const remainingCount = useMemo(() => requirements?.totalElements - (page + 1) * 50, [requirements, page]);
  const handleUpdateTitleForContents = useCallback(
    ({ paragraphIds }) =>
      ({ title }) => {
        updateTitleForParagraphs({ projectId, deliverableId }, { ids: paragraphIds, title })
          .then(() => {
            if (typeof sidePanelRef.current?.updateParagraphTitlesUI === "function") {
              sidePanelRef.current.updateParagraphTitlesUI(title, paragraphIds);
            }
          })
          .catch(console.error);
      },
    [deliverableId, projectId]
  );
  const handleUpdateParagraph = useCallback(
    ({ content, contentHTML, paragraphOutId }) =>
      ({ title }) => {
        updateParagraph(
          { projectId, deliverableId, paragraphOutId },
          { page: 1, title, content, contentHTML, order_in_doc: 1 }
        )
          .then(() => {
            if (typeof sidePanelRef.current?.updateParagraphUI === "function") {
              sidePanelRef.current.updateParagraphUI({ paragraphOutId, title, content, contentHTML });
            }
          })
          .catch(console.error);
      },
    []
  );

  useEffect(() => {
    const onSelectionChange = () => {
      WordUtils.changeSelection(setSelectedLinkId, setSelectedReqId, deliverableId);
    };
    const onDetectChanges = () => {
      WordUtils.detectChanges(handleUpdateTitleForContents, handleUpdateParagraph);
    };
    Office.onReady((info) => {
      if (info.host === Office.HostType.Word) {
        Office.context.document?.addHandlerAsync(Office.EventType.DocumentSelectionChanged, onSelectionChange);
        Office.context.document.addHandlerAsync(Office.EventType.DocumentSelectionChanged, onDetectChanges);
      }
    });
    return () => {
      Office.context.document?.removeHandlerAsync(Office.EventType.DocumentSelectionChanged, onSelectionChange);
      Office.context.document.removeHandlerAsync(Office.EventType.DocumentSelectionChanged, onDetectChanges);
    };
  }, [Office.context.document, deliverableId]);

  useEffect(() => {
    if (updateReqList) {
      getRequirementsForDeliverables({ projectId, deliverableId }, { page, limit: 50, isCovered })
        .then((data) => {
          if (page === 0) {
            setRequirements(data);
          } else {
            setRequirements((prev) => ({ ...data, contents: [...prev.contents, ...data.contents] }));
          }
        })
        .catch(console.error)
        .finally(() => dispatch(setUpdateReqList(false)));
    }
  }, [projectId, deliverableId, isCovered, page, updateReqList]);
  useEffect(() => {
    if (selectedReqId) {
      getRequirementInformation({ reqId: selectedReqId })
        .then((data) =>
          setSelectedReqDisplay({
            clientRef: data.clientRef,
            content: data.content,
            documentId: data.documentId,
            documentName: data.documentName,
            id: data.id,
            pageStart: data.page,
          })
        )
        .catch(console.error);
    }
  }, [selectedReqId, getRequirementInformation]);
  useEffect(() => {
    setPage(0);
  }, [selectedTab]);
  const handleIncrementPage = () => {
    if (remainingCount > 0) {
      setPage((prev) => prev + 1);
      dispatch(setUpdateReqList(true));
    }
  };
  const handleResetSelection = () => {
    setSelectedLinkId(0);
    setSelectedReqId(0);
  };
  const handleUpdateReqCounter = ({ reqId, increment = false }) => {
    const tempRequirements = requirements.contents;
    setRequirements((prev) => ({
      ...prev,
      contents: tempRequirements.map((req) => {
        if (req.id === reqId) {
          return { ...req, count: increment ? req.count + 1 : req.count - 1 };
        }
        return req;
      }),
    }));
  };
  const handleUpdateListOfRequirements = ({ value, reqId }) => {
    const tempRequirements = requirements.contents;
    if (selectedTab === "ALL") {
      setRequirements((prev) => ({
        ...prev,
        contents: tempRequirements.map((req) => {
          if (req.id === reqId) {
            return { ...req, covered: value };
          }
          return req;
        }),
      }));
    } else {
      setRequirements((prev) => ({
        ...prev,
        contents: tempRequirements.filter((req) => req.id !== reqId),
      }));
    }
  };
  const handleUpdateRequirementCoverage = ({ reqId, value }) => {
    updateRequirementCoverage({ projectId, reqId, deliverableId }, { value })
      .then(() => {
        onUpdateCount(value);
        handleUpdateListOfRequirements({ value, reqId });
      })
      .catch(console.error);
  };
  const handleOpen = (req) => {
    setSelectedReqDisplay(req);
  };
  const handleClose = () => {
    setSelectedReqId(0);
    setSelectedReqDisplay({});
    handleResetSelection();
  };
  return (
    <div className={styles.container}>
      <div className={styles.listContainer} data-has-more={remainingCount > 0}>
        {(isNonEmptyArray(requirements?.contents) &&
          requirements?.contents.map((req) => (
            <RequirementBox
              key={req.id}
              row={req}
              onOpen={handleOpen}
              onUpdateReqCounter={handleUpdateReqCounter}
              onUpdateRequirementCoverage={handleUpdateRequirementCoverage}
            />
          ))) || (
          <div className={styles.emptyState}>
            <div className={styles.emptyState__header}>{translate("common:empty-state.no-results")}</div>
            <div className={styles.emptyState__content}>
              <span>{translate("requirements-to-deliverables.requirement-list.empty-state.to-cover")}</span>
              <CustomButtonLink
                external
                openInNewTab
                buttonClassName={styles.emptyState__link}
                to={`https://${domain}/projects/${projectId}/follow-up/deliverables/assign-to-deliverables?companyId=${companyId}`}
              >
                {translate("requirements-to-deliverables.requirement-list.empty-state.assign-to-deliv")}
              </CustomButtonLink>
              <span className={styles.emptyState__lowerCase}>{translate("common:enum.separator.or")}</span>
              <CustomButtonLink
                external
                openInNewTab
                buttonClassName={styles.emptyState__link}
                to={`https://${domain}/projects/${projectId}/review/my-review?companyId=${companyId}`}
              >
                {translate("requirements-to-deliverables.requirement-list.empty-state.my-review")}
              </CustomButtonLink>
              <span className={styles.emptyState__lowerCase}>
                {translate("requirements-to-deliverables.requirement-list.empty-state.page-of-project")}
              </span>
            </div>
          </div>
        )}
      </div>
      {remainingCount > 0 && (
        <CustomButton className={styles.button} color="secondary" variant="outlined" onClick={handleIncrementPage}>
          {translate("requirements-to-deliverables.requirement-list.view-more", {
            count: remainingCount,
          })}
        </CustomButton>
      )}
      <RequirementSidePanel
        ref={sidePanelRef}
        open={!!selectedReqDisplay.id}
        req={selectedReqDisplay}
        selectedLinkId={selectedLinkId}
        onClose={handleClose}
        onUpdateReqCounter={handleUpdateReqCounter}
      />
    </div>
  );
};

export default RequirementList;
