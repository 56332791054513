import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  CircularLoader,
  CustomAccordion,
  CustomButton,
  CustomIconButton,
  icon,
} from "../../../../../common/components";
import styles from "./Edition.module.css";
import { translate } from "../../../../../common/providers";
import CustomTextField from "../../../../../common/components/text-fields/text-field/CustomTextField";
import {
  decrementEditContentIndex,
  incrementEditContentIndex,
  setEditContentIndex,
  setLoadingAnswer,
} from "../../../slice/multi-project-search-slice";
import { useDispatch, useSelector } from "react-redux";
import { ContentService } from "../../../../../api";
import { useApi } from "../../../../../common/hooks";
import { Skeleton, TextField } from "@mui/material";
import { OptionsMenu, QuickAction } from "./components";
import { debounce, isNonEmptyArray } from "../../../../../common/utils";

const debouncedFunction = debounce((func) => func(), 500);

const Edition = () => {
  const { call: askQuestion } = useApi(ContentService.askQuestion);
  const { call: getAskedQuestion } = useApi(ContentService.getAskedQuestion);
  const { call: getAskedQuestionCount } = useApi(ContentService.getAskedQuestionCount);
  const { call: editAskedQuestionContent } = useApi(ContentService.editAskedQuestionContent);
  const loadingAnswer = useSelector(({ multiProjectSearch }) => multiProjectSearch.loadingAnswer);
  const currentEditContentIndex = useSelector(({ multiProjectSearch }) => multiProjectSearch.currentEditContentIndex);
  const selectedQuickActions = useSelector(({ multiProjectSearch }) => multiProjectSearch.selectedQuickActions);
  const [chatsCount, setChatsCount] = useState(0);
  const [currentContent, setCurrentContent] = useState(null);
  const [question, setQuestion] = useState("");
  const [shiftPressed, setShiftPressed] = useState(false);
  const [editableContent, setEditableContent] = useState(currentContent);
  const [loadingCount, setLoadingCount] = useState(true);
  const dispatch = useDispatch();
  const quickActions = useMemo(
    () => [
      { title: translate("multi-projects-search.edition.quick-actions.correct-spelling") },
      { title: translate("multi-projects-search.edition.quick-actions.improve-synthesis") },
      { title: translate("multi-projects-search.edition.quick-actions.short-synthesis") },
      { title: translate("multi-projects-search.edition.quick-actions.reduce") },
      { title: translate("multi-projects-search.edition.quick-actions.write-past") },
      { title: translate("multi-projects-search.edition.quick-actions.write-future") },
    ],
    []
  );
  const handleAskQuestion = useCallback(() => {
    if (loadingAnswer || shiftPressed) {
      return;
    }
    if ((question || isNonEmptyArray(selectedQuickActions)) && editableContent) {
      dispatch(setLoadingAnswer(true));
      askQuestion({
        question,
        predefinedPrompts: selectedQuickActions,
        tabId: currentEditContentIndex,
        content: editableContent,
      })
        .then(() => {
          dispatch(setEditContentIndex(chatsCount <= 0 ? 2 : chatsCount + 1));
          setChatsCount((prev) => (prev <= 0 ? 2 : prev + 1));
          setQuestion("");
        })
        .catch(console.error)
        .finally(() => dispatch(setLoadingAnswer(false)));
    }
  }, [question, currentContent, editableContent, selectedQuickActions, loadingAnswer, shiftPressed]);
  useEffect(() => {
    const handleKeyPress = (e) => {
      if (e.repeat) {
        return;
      }
      if (e.key === "Enter") {
        handleAskQuestion();
      }
      if (e.key === "Shift") {
        setShiftPressed(true);
      }
    };
    const handleKeyRelease = (e) => {
      if (e.key === "Shift") {
        setShiftPressed(false);
      }
    };
    window.addEventListener("keydown", handleKeyPress, true);
    window.addEventListener("keyup", handleKeyRelease, true);
    return () => {
      window.removeEventListener("keydown", handleKeyPress, true);
      window.removeEventListener("keyup", handleKeyRelease, true);
    };
  }, [handleAskQuestion]);
  useEffect(() => {
    if (!loadingCount) {
      getAskedQuestion({ tabId: currentEditContentIndex })
        .then((data) => {
          setCurrentContent(data?.response?.value || "");
        })
        .catch(console.error);
    }
  }, [getAskedQuestion, currentEditContentIndex, chatsCount, loadingCount]);
  useEffect(() => {
    setLoadingCount(true);
    getAskedQuestionCount()
      .then((data) => {
        setChatsCount(data);
        dispatch(setEditContentIndex(data <= 0 ? 1 : data));
      })
      .catch(console.error)
      .finally(() => setLoadingCount(false));
  }, [getAskedQuestionCount]);
  useEffect(() => {
    setEditableContent(currentContent || "");
  }, [currentContent]);
  const handleIncrement = () => {
    dispatch(incrementEditContentIndex());
  };
  const handleDecrement = () => {
    dispatch(decrementEditContentIndex());
  };
  const handleChangeQuestion = (e) => {
    setQuestion(e.target.value);
  };
  const handleModifyEditableContent = (e) => {
    const { value } = e.target;
    setEditableContent(value);
    if (value) {
      debouncedFunction(() => {
        editAskedQuestionContent({ value }, { tabId: currentEditContentIndex }).catch(console.error);
      });
    }
  };
  const handleResetTabs = () => {
    setEditableContent("");
    setChatsCount(0);
    dispatch(setEditContentIndex(1));
  };
  const handleIncrementChatCount = () => {
    setChatsCount((prev) => (prev === 0 ? 2 : prev + 1));
    setEditableContent("");
    setQuestion("");
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.header__leftSide}>
          <>
            <CustomIconButton
              btnClassName={styles["btn--transparent"]}
              disabled={currentEditContentIndex <= 1}
              icon={icon.faChevronLeft}
              size="sm"
              variant="outlined"
              onClick={handleDecrement}
            />
            <span>
              {currentEditContentIndex}/{chatsCount <= 0 ? 1 : chatsCount}
            </span>
            <CustomIconButton
              btnClassName={styles["btn--transparent"]}
              disabled={currentEditContentIndex >= chatsCount}
              icon={icon.faChevronRight}
              size="sm"
              variant="outlined"
              onClick={handleIncrement}
            />
          </>
        </div>
        <div className={styles.header__rightSide}>
          <OptionsMenu
            chatsCount={chatsCount}
            insertableContent={editableContent}
            onIncrementChatCount={handleIncrementChatCount}
            onResetTabs={handleResetTabs}
          />
        </div>
      </div>
      {(loadingAnswer && (
        <div className={styles.information} data-is-loading={loadingAnswer}>
          <CircularLoader />
        </div>
      )) || (
        <TextField
          multiline
          className={styles.informationTextField}
          value={editableContent}
          onChange={handleModifyEditableContent}
        />
      )}
      <CustomAccordion
        className={styles.accordionContent}
        header={
          <div className={styles.accordationTitle}>{translate("multi-projects-search.edition.quick-actions")}</div>
        }
      >
        <div className={styles.quickAction__container}>
          {isNonEmptyArray(quickActions) &&
            quickActions.map((quickAction) => <QuickAction key={quickAction.title} title={quickAction.title} />)}
        </div>
      </CustomAccordion>
      <div className={styles.search}>
        {loadingAnswer ? (
          <Skeleton className={styles.skeleton} sx={{ fontSize: "2rem" }} />
        ) : (
          <CustomTextField
            fullWidth
            placeholder={translate("multi-projects-search.edition.textfield.placeholder")}
            size="sm"
            value={question}
            onChange={handleChangeQuestion}
          />
        )}
        <CustomButton
          disabled={(!question && !isNonEmptyArray(selectedQuickActions)) || !editableContent}
          variant="contained"
          onClick={handleAskQuestion}
        >
          {translate("common:btn.validate")}
        </CustomButton>
      </div>
      <div className={styles.helperText}>{translate("multi-projects-search.ai-helper-text")}</div>
    </div>
  );
};

export default Edition;
