import React, { useEffect, useState } from "react";
import { SelectionContainer } from "../";
import { Radio } from "@mui/material";
import { useApi } from "../../../../../common/hooks";
import { LinkReqToDelivService } from "../../../../../api";
import { useDispatch, useSelector } from "react-redux";
import styles from "../EnvironmentSelection.module.css";
import { translate } from "../../../../../common/providers";
import { CustomTooltip } from "../../../../../common/components";
import { setDeliverable, setDeliverableToUpdate, setProjectReqToDelivToUpdate } from "../../../../../common/slice";

const DeliverableSelection = () => {
  const { call: getDeliverables } = useApi(LinkReqToDelivService.getDeliverables);
  const projectReqToDelivId = useSelector(({ context }) => context.projectReqToDeliv.id);
  const deliverable = useSelector(({ context }) => context.deliverable);
  const [request, setRequest] = useState(null);
  const [selectedDeliverable, setSelectedDeliverable] = useState(deliverable);
  const dispatch = useDispatch();
  useEffect(() => {
    setRequest(
      () =>
        ({ page, limit, search }) =>
          getDeliverables({ projectId: projectReqToDelivId }, { page, limit, filter: search })
    );
  }, [projectReqToDelivId]);
  const handleSelectProject = (row) => {
    setSelectedDeliverable(row);
  };
  const rowRenderer = (row) => {
    return (
      <div className={styles.rowContainer} role="presentation" onClick={() => handleSelectProject(row)}>
        <Radio radio checked={row.id === selectedDeliverable?.id} />
        <CustomTooltip showWhenOverflow title={row.name}>
          <div className={styles.name}>{row.name}</div>
        </CustomTooltip>
        <CustomTooltip showWhenOverflow title={row.author}>
          <div className={styles.name}>{row.author}</div>
        </CustomTooltip>
      </div>
    );
  };

  const headerRow = () => (
    <div className={styles.headerRowContainer}>
      <div className={styles.radio} />
      <div className={styles.name}>{translate("common:project-selection-side-pane.name")}</div>
      <div className={styles.name}>{translate("common:project-selection-side-pane.author")}</div>
    </div>
  );
  const handleValidate = () => {
    dispatch(setDeliverable({ name: selectedDeliverable.name, id: selectedDeliverable.id }));
    dispatch(setDeliverableToUpdate(false));
  };
  const handleBack = () => {
    dispatch(setProjectReqToDelivToUpdate(true));
  };
  return (
    <SelectionContainer
      disableValidate={!selectedDeliverable}
      headerRow={headerRow}
      request={request}
      rowRenderer={rowRenderer}
      title="Sélectionner un livrable"
      onBack={handleBack}
      onValidate={handleValidate}
    />
  );
};

export default DeliverableSelection;
