import client from "../client/client";

class SearchCardService {
  countResults({ projectId }, filters, cancelToken) {
    return client.post(`/searchcard/projects/${projectId}/count-not-deliverable`, {
      payload: filters,
      config: { cancelToken },
    });
  }

  search({ projectId, page, limit }, filters, cancelToken) {
    return client.post(`/searchcard/projects/${projectId}/search-not-deliverable`, {
      params: { page, limit },
      payload: filters,
      config: { cancelToken },
    });
  }
  getProjectFilters({ projectId }, cancelToken) {
    return client.get(`/searchcard/projects/${projectId}/saved/search-not-deliverable/filter`, {
      config: { cancelToken },
    });
  }
}

export default new SearchCardService();
