import React, { useEffect, useMemo, useState } from "react";
import styles from "./RequirementBox.module.css";
import { CustomButton, Hierarchy } from "../../../../common/components";
import { translate } from "../../../../common/providers";
import RequirementSidePanel from "../requirement-side-panel/RequirementSidePanel";
const RequirementBox = ({ row, selectedLinkId, selectedReqId, onResetSelection }) => {
  const [open, setOpen] = useState(false);
  const coverage = useMemo(() => {
    if (row.coveredIn === 1) {
      return translate("requirements-to-deliverables.requirement-box.paragraph", { count: row.coveredIn });
    }
    if (row.coveredIn > 1) {
      return translate("requirements-to-deliverables.requirement-box.paragraphs", { count: row.coveredIn });
    }
    return translate("requirements-to-deliverables.requirement-box.to-cover");
  }, [row.coveredIn]);
  useEffect(() => {
    setOpen(row.id === selectedReqId);
  }, [row, selectedReqId]);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    onResetSelection();
  };
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.header__leftSide}>REQ_{row.id}</div>
        <div className={styles.header__rightSide}>
          <span className={styles.cover} data-covered={!!row.coveredIn}>
            {coverage}
          </span>
          <CustomButton color="secondary" size="sm" variant="outlined" onClick={handleOpen}>
            {translate("common:btn.details")}
          </CustomButton>
        </div>
      </div>
      <div className={styles.content}>{row.content}</div>
      <div>
        <Hierarchy
          enableFirstLocationCut
          isDangerousSetInnerHTML
          className={styles.breadcrumbContainer}
          documentName={row.documentName}
          hierarchy={row.location}
        />
        <RequirementSidePanel
          content={row.content}
          coverage={coverage}
          isCovered={!!row.coveredIn}
          open={open}
          reqId={row.id}
          selectedLinkId={selectedLinkId}
          onClose={handleClose}
        />
      </div>
    </div>
  );
};

export default RequirementBox;
