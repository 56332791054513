import React, { useEffect, useState } from "react";
import styles from "./Generate.module.css";
import { CustomAccordion, CustomButton } from "../../../../../common/components";
import { translate } from "../../../../../common/providers";
import { useApi } from "../../../../../common/hooks";
import { ContentService } from "../../../../../api";
import { useSelector } from "react-redux";
import { UserPrompts, GenerationActions } from "./components";
import { SavedContents } from "./components";

const Generate = () => {
  const { call: getSavedContent } = useApi(ContentService.getSavedContent);
  const { call: deleteAllSavedContent } = useApi(ContentService.deleteAllSavedContent);
  const { call: deleteSavedContent } = useApi(ContentService.deleteSavedContent);
  const { call: getGenerationState } = useApi(ContentService.getGenerationState);
  const { call: saveGenerationState } = useApi(ContentService.saveGenerationState);
  const projectId = useSelector(({ context }) => context.project?.id);
  const [integrationCheck, setIntegrationCheck] = useState(false);
  const [savedProjectContent, setSavedProjectContent] = useState([]);
  const [savedDeliverableContent, setSavedDeliverableContent] = useState([]);
  const [additionalContext, setAdditionalContext] = useState([]);
  const [stateHasChanges, setStateHasChanges] = useState(false);
  useEffect(() => {
    getSavedContent({ projectId: undefined })
      .then((data) => setSavedDeliverableContent(data))
      .catch(console.error);
    if (projectId) {
      getSavedContent({ projectId })
        .then((data) => setSavedProjectContent(data))
        .catch(console.error);
    }
  }, [projectId]);
  useEffect(() => {
    getGenerationState()
      .then((data) => {
        if (data) {
          setAdditionalContext(data.userPrompts);
          setIntegrationCheck(data.integratingProjectContent);
        }
      })
      .catch(console.error);
  }, []);
  useEffect(() => {
    if (stateHasChanges) {
      saveGenerationState({ integratingProjectContent: integrationCheck, userPrompts: additionalContext })
        .then(() => setStateHasChanges(false))
        .catch(console.error);
    }
  }, [additionalContext, integrationCheck, stateHasChanges]);

  const handleIntegrationCheck = () => {
    setIntegrationCheck((prev) => !prev);
    setStateHasChanges(true);
  };
  const handleDeleteAllDeliverable = () => {
    deleteAllSavedContent({ projectId: undefined })
      .then(() => setSavedDeliverableContent([]))
      .catch(console.error);
  };
  const handleDeleteAllProject = () => {
    deleteAllSavedContent({ projectId })
      .then(() => setSavedProjectContent([]))
      .catch(console.error);
  };
  const handleDeleteAllUserContext = () => {
    setAdditionalContext([]);
    setStateHasChanges(true);
  };
  const handleRemoveUserPrompt = (value) => {
    setAdditionalContext((prev) => prev.filter((p) => p !== value));
    setStateHasChanges(true);
  };

  const handleAddUserPrompt = (newUserPromp) => {
    setAdditionalContext((prev) => [...prev, newUserPromp]);
    setStateHasChanges(true);
  };
  const handleDeleteProjectContent = (idToDelete) => {
    deleteSavedContent({ savedDataId: idToDelete })
      .then(() => setSavedProjectContent((prev) => prev.filter((p) => p.savedDataId !== idToDelete)))
      .catch(console.error);
  };
  const handleDeleteDeliverableContent = (idToDelete) => {
    deleteSavedContent({ savedDataId: idToDelete })
      .then(() => setSavedDeliverableContent((prev) => prev.filter((p) => p.savedDataId !== idToDelete)))
      .catch(console.error);
  };

  return (
    <div className={styles.container}>
      <div className={styles.innerContainer}>
        <CustomAccordion
          className={styles.accordionContent}
          header={
            <div className={styles.boxTitle}>
              <span>{translate("multi-projects-search.edition.quick-actions")}</span>
              <CustomButton
                color="secondary"
                disabled={additionalContext.length <= 0}
                variant="outlined"
                onClick={handleDeleteAllUserContext}
              >
                {translate("common:btn.delete-all")}
              </CustomButton>
            </div>
          }
        >
          <UserPrompts
            additionalContext={additionalContext}
            onAdd={handleAddUserPrompt}
            onRemove={handleRemoveUserPrompt}
          />
        </CustomAccordion>
        <div className={styles.boxTitle}>{translate("multi-projects-search.summary-side-panel.tabs.sources")}</div>
        <div className={styles.sourceContainer}>
          <CustomAccordion
            className={styles.accordionContent}
            header={
              <div className={`${styles.boxTitle} ${styles["text--regular"]}`}>
                <span>
                  {translate("multi-projects-search.tab-header.deliverable")}&nbsp;({savedDeliverableContent.length})
                </span>
                <CustomButton
                  color="secondary"
                  disabled={savedDeliverableContent <= 0}
                  variant="outlined"
                  onClick={handleDeleteAllDeliverable}
                >
                  {translate("common:btn.delete-all")}
                </CustomButton>
              </div>
            }
          >
            <SavedContents savedContents={savedDeliverableContent} onDelete={handleDeleteDeliverableContent} />
          </CustomAccordion>
          <CustomAccordion
            className={styles.accordionContent}
            header={
              <div className={`${styles.boxTitle} ${styles["text--regular"]}`}>
                <span>
                  {translate("multi-projects-search.tab-header.project")}&nbsp;({savedProjectContent.length})
                </span>
                <CustomButton
                  color="secondary"
                  disabled={!projectId || savedProjectContent.length <= 0}
                  variant="outlined"
                  onClick={handleDeleteAllProject}
                >
                  {translate("common:btn.delete-all")}
                </CustomButton>
              </div>
            }
          >
            <SavedContents
              projectContentDeletion
              savedContents={savedProjectContent}
              onDelete={handleDeleteProjectContent}
            />
          </CustomAccordion>
        </div>
      </div>
      <GenerationActions
        integrationCheck={integrationCheck}
        savedDeliverableContentCount={savedDeliverableContent.length}
        savedProjectContentCount={savedProjectContent.length}
        onIntegrationCheck={handleIntegrationCheck}
      />
    </div>
  );
};

export default Generate;
