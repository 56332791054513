import React, { cloneElement, useEffect, useRef, useState, useMemo, useCallback } from "react";
import { useSelector } from "react-redux";
import { useEffectOnce } from "../../../../hooks";
import { debounce, isDeepEqual, isNonEmptyArray, isValueActive } from "../../../../utils";
import { FiltersSelector, FilterSearch } from "../components";
import styles from "./FiltersWrapper.module.css";
import {
  CustomAccordion,
  CustomButton,
  CustomIconButton,
  VerticalDivider,
  getActiveFiltersCount,
  icon,
} from "../../..";
import { FilterContent } from "../";
import { /*Flags, isFeatureEnabled,*/ translate } from "../../../../providers";
import { KEYWORD_FILTER_OPERATOR, OPERATORS } from "../../../../constants";
import { FilterSelector } from "../../components";

const SELECTOR_WIDTH = 64;
const FILTER_WIDTH = 130;
const debouncedFunction = debounce((func) => func(), 500);
const debouncedFunctionNoDelay = debounce((func) => func(), 500);
export default function FiltersWrapper({
  addFilterButtonVariant = "outlined",
  className,
  components,
  collapsableFilters = false,
  customLabelApply = null,
  defaultFilters = {},
  disablePortal = true,
  disableWidthAdjustments = false,
  disableNoResult = false,
  filterPopUpPlacement,
  hasSearch = false,
  hasLargeSearch = false,
  hasSeparator = false,
  multiline = false,
  onApply,
  onRemove,
  previewRequest,
  searchClassName = "",
  searchLabel = "",
  searchPlaceHolder,
  secondaryComponents,
}) {
  const [filters, setFilters] = useState({});
  const [tempFilters, setTempFilters] = useState({});
  const [previewCount, setPreviewCount] = useState(null);
  const [search, setSearch] = useState("");
  const [isPreviewLoading, setIsPreviewLoading] = useState(false);
  const [permanentKeys, setPermanentKeys] = useState([]);
  const [displayedKeys, setDisplayedKeys] = useState([]);
  const [scrollIndex, setScrollIndex] = useState(0);
  const [separator, setSeparator] = useState(OPERATORS.AND);
  const [showArrows, setShowArrows] = useState(false);
  const keyJustAdded = useRef(false);
  const containerRef = useRef(null);
  const contentRef = useRef(null);
  const searchData = useSelector(({ srDocument }) => srDocument?.searchData);
  const searchWidth = useMemo(() => (hasSearch ? 218 : 0), [hasSearch]);
  const filtersCount = useMemo(() => getActiveFiltersCount(defaultFilters), [defaultFilters]);
  useEffectOnce(
    () => {
      const newFilters = JSON.parse(JSON.stringify(defaultFilters));
      setFilters({ newFilters });
      setSearch(newFilters.content);
      setTempFilters(newFilters);
    },
    [defaultFilters],
    () => isValueActive(defaultFilters)
  );
  useEffect(() => {
    if (defaultFilters) {
      setFilters(defaultFilters);
      setTempFilters(defaultFilters);
      if (defaultFilters.content) {
        setSearch(defaultFilters.content);
      } else {
        setSearch("");
      }
    }
  }, [defaultFilters]);
  const resizeListener = useCallback(() => {
    if (multiline) {
      return;
    }
    if (
      contentRef?.current?.scrollWidth >
      (containerRef?.current?.offsetWidth || 0) - SELECTOR_WIDTH - FILTER_WIDTH - searchWidth
    ) {
      setShowArrows(true);
    } else {
      setShowArrows(false);
    }
  }, [searchWidth, multiline]);
  useEffect(() => {
    setScrollIndex(0);
  }, [showArrows]);
  useEffect(() => {
    debouncedFunctionNoDelay(() => {
      resizeListener();
    });
  }, [containerRef?.current, searchData, displayedKeys]);

  useEffect(() => {
    window.addEventListener("resize", resizeListener);
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, [resizeListener]);
  useEffect(() => {
    if (hasSeparator) {
      setSeparator(defaultFilters.separator);
    }
  }, [defaultFilters, hasSeparator]);
  const generateDisplayedKeys = useCallback(() => {
    const activeKeys = Object.keys(filters).filter((key) => isValueActive(filters[key]));
    let keysToDisplay = [];
    activeKeys.forEach((key) => {
      if (key === "keywordFilters" || key === "tocFilters") {
        filters[key].filters.forEach((_, index) => {
          keysToDisplay = [...keysToDisplay, { name: key, index }];
        });
      } else {
        keysToDisplay = [...keysToDisplay, { name: key }];
      }
    });
    return keysToDisplay;
  }, [filters]);
  useEffect(() => {
    setDisplayedKeys(generateDisplayedKeys());
  }, [filters, generateDisplayedKeys]);
  const handleApply = () => {
    keyJustAdded.current = false;
    const newFilters = JSON.parse(JSON.stringify(tempFilters));
    setFilters(newFilters);
    setDisplayedKeys(generateDisplayedKeys());
    onApply(newFilters);
  };
  const handleClickKey = (newKey) => {
    keyJustAdded.current = true;
    let index;
    if (newKey === "tocFilters" || newKey === "keywordFilters") {
      index = filters[newKey]?.filters?.length || 0;
    }
    setDisplayedKeys((prev) => [{ name: newKey, index }, ...prev]);
  };
  const handleChangeTempFilter = ({ newTempFilter, directApply = false, justClosed = false, filterIndex }) => {
    const key = Object.keys(newTempFilter)[0];
    if (justClosed) {
      keyJustAdded.current = false;
      const isActive = isValueActive(Object.values(newTempFilter)[0], key, filterIndex) || permanentKeys.includes(key);
      if (!isActive) {
        setDisplayedKeys((prev) =>
          prev.filter((displayedKey) => !(displayedKey.name === key && displayedKey.index === filterIndex))
        );
      }
    }
    let newTempFilters = { ...tempFilters };
    if (tempFilters[key] !== undefined && filterIndex !== undefined) {
      if (!justClosed) {
        const innerFilter = [...tempFilters[key].filters];
        innerFilter?.splice(filterIndex, 1);
        if (innerFilter.length === 0) {
          newTempFilters = { ...tempFilters, [key]: undefined };
        } else {
          newTempFilters = { ...tempFilters, [key]: { filters: innerFilter } };
        }
      }
    } else {
      newTempFilters = { ...tempFilters, ...newTempFilter };
    }
    setTempFilters(newTempFilters);
    if (!directApply && !!previewRequest) {
      setIsPreviewLoading(true);
      debouncedFunction(() => {
        previewRequest({ ...newTempFilters })
          .then((count) => {
            setPreviewCount(count);
          })
          .catch((err) => {
            console.error(err);
          })
          .finally(() => {
            setIsPreviewLoading(false);
          });
      });
    } else if (directApply) {
      const newFilters = JSON.parse(JSON.stringify(newTempFilters));
      setFilters(newFilters);
      const isActive = isValueActive(Object.values(newTempFilter)[0]) || permanentKeys.includes(key);
      if (!isActive) {
        setDisplayedKeys((prev) => prev.filter((displayedKey) => displayedKey !== key));
      }
      onApply(newFilters);
      if (typeof onRemove === "function") {
        onRemove(newFilters);
      }
    }
  };
  const handleForceDisplay = (key, displayForced) => {
    if (!permanentKeys.includes(key) && displayForced) {
      setPermanentKeys((prev) => [...prev, key]);
      setDisplayedKeys((prev) => prev.filter((k) => k !== key));
    }
  };
  const handleTextSearch = (content) => {
    let newFilters;
    if (content?.length > 0) {
      newFilters = { ...JSON.parse(JSON.stringify(tempFilters)), content };
    } else {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { content: _, ...rest } = tempFilters; // NOSONAR
      newFilters = rest;
    }
    setSearch(content);
    setFilters(newFilters);
    setTempFilters(newFilters);
    if (hasSearch) {
      onApply(newFilters);
    }
  };
  const isApplyDisabled = (filterKey, filterIndex) => {
    const cleanedTempFilter = isValueActive(tempFilters[filterKey], filterKey, filterIndex)
      ? tempFilters[filterKey]
      : undefined;
    const cleanedFilter = isValueActive(filters[filterKey], filterKey, filterIndex) ? filters[filterKey] : undefined;
    return cleanedFilter === cleanedTempFilter || isDeepEqual(cleanedFilter, cleanedTempFilter);
  };
  const getIndividualAppliedFilters = () => {
    let tempComponents = components.filter(
      (cpt) => cpt?.default || displayedKeys.some((displayKey) => displayKey.name === cpt.component.filterKey)
    );
    if (Array.isArray(filters?.tocFilters?.filters) && filters.tocFilters.filters.length > 0) {
      const tocComponent = tempComponents.find((cpt) => cpt.component.filterKey === "tocFilters");
      if (tocComponent) {
        tempComponents = tempComponents.filter(
          (cpt) => !(cpt.component.filterKey === "tocFilters" && cpt.component.filterIndex === undefined)
        );
        displayedKeys.forEach((displayKey) => {
          if (displayKey.name === "tocFilters") {
            tempComponents.push({
              ...tocComponent,
              component: { ...tocComponent.component, filterIndex: displayKey.index },
            });
          }
        });
      }
    }
    if (Array.isArray(filters?.keywordFilters?.filters) && filters.keywordFilters.filters.length > 0) {
      const keywordComponent = tempComponents.find((cpt) => cpt.component.filterKey === "keywordFilters");
      if (keywordComponent) {
        tempComponents = tempComponents.filter(
          (cpt) => !(cpt.component.filterKey === "keywordFilters" && cpt.component.filterIndex === undefined)
        );
        displayedKeys.forEach((displayKey) => {
          if (displayKey.name === "keywordFilters") {
            tempComponents.push({
              ...keywordComponent,
              component: { ...keywordComponent.component, filterIndex: displayKey.index },
            });
          }
        });
      }
    }
    return tempComponents;
  };
  const handleHasNegative = (filterKey) =>
    (filterKey === "tocFilters" &&
      filters?.tocFilters?.filters.some(
        (tocFilter) =>
          tocFilter.operator === KEYWORD_FILTER_OPERATOR.IS_NOT_EXACTLY ||
          tocFilter.operator === KEYWORD_FILTER_OPERATOR.START_NOT_WITH
      )) ||
    (filterKey === "keywordFilters" &&
      filters?.keywordFilters?.filters.some(
        (tocFilter) =>
          tocFilter.operator === KEYWORD_FILTER_OPERATOR.IS_NOT_EXACTLY ||
          tocFilter.operator === KEYWORD_FILTER_OPERATOR.START_NOT_WITH
      ));
  const handleLastPositive = (filterKey) =>
    (
      filterKey === "tocFilters" &&
      tempFilters?.tocFilters?.filters.filter(
        (tocFilter) =>
          tocFilter.operator === KEYWORD_FILTER_OPERATOR.IS_EXACTLY ||
          tocFilter.operator === KEYWORD_FILTER_OPERATOR.START_WITH
      )
    )?.length <= 1 ||
    (
      filterKey === "keywordFilters" &&
      tempFilters?.keywordFilters?.filters.filter(
        (tocFilter) =>
          tocFilter.operator === KEYWORD_FILTER_OPERATOR.IS_EXACTLY ||
          tocFilter.operator === KEYWORD_FILTER_OPERATOR.START_WITH
      )
    )?.length <= 1;
  const renderChild = (child) => {
    if (!child?.component?.filterKey || !child?.enabled) {
      return null;
    }
    const { component, ...rest } = child;
    const { filterKey, filterIndex } = component;
    const filterKeyToOpen = keyJustAdded.current ? displayedKeys[0].name : "";
    const hasNegative = handleHasNegative(filterKey);
    const lastPositive = handleLastPositive(filterKey);
    const adjustedFilterIndex =
      filterKey === "tocFilters" || filterKey === "keywordFilters" ? filterIndex || 0 : filterIndex;
    return cloneElement(
      <FilterContent
        disablePortal={disablePortal}
        filterKey={filterKey}
        filterPopUpPlacement={filterPopUpPlacement}
        {...component}
        {...rest}
      />,
      {
        ...child.props,
        key: `${filterKey}${adjustedFilterIndex}`,
        onChange: (newValue, directApply) =>
          handleChangeTempFilter({ newTempFilter: { [filterKey]: newValue }, directApply }),
        value: tempFilters[filterKey],
        appliedValue: filters[filterKey],
        lastPositive,
        globalSeparator: tempFilters.separator,
        filterIndex: adjustedFilterIndex,
        applyDisabled: isApplyDisabled(filterKey, adjustedFilterIndex),
        isActive: isValueActive(filters[filterKey], filterKey, adjustedFilterIndex),
        disableNoResult,
        hasNegative,
        isPreviewLoading,
        previewCount,
        keyJustAdded: keyJustAdded.current,
        customLabelApply,
        onApply: handleApply,
        onRemove: () =>
          handleChangeTempFilter({
            newTempFilter: { [filterKey]: undefined },
            directApply: true,
            filterIndex: adjustedFilterIndex,
          }),
        onClearSelection: () => setTempFilters({ ...tempFilters, [filterKey]: undefined }),
        onReinit: () => handleChangeTempFilter({ newTempFilter: { [filterKey]: undefined } }),
        onClose: () =>
          handleChangeTempFilter({
            newTempFilter: { [filterKey]: filters[filterKey] },
            justClosed: true,
            filterIndex: adjustedFilterIndex,
          }),
        defaultOpened:
          filterKeyToOpen === displayedKeys[0]?.name &&
          (filterIndex === displayedKeys[0]?.index || filterIndex === undefined) &&
          filterKeyToOpen === filterKey,
        onMount: (displayForced) => handleForceDisplay(filterKey, displayForced),
        hidden: !child?.default && !displayedKeys.some((displayKey) => displayKey.name === filterKey),
      }
    );
  };
  const additionalFilters = components
    .filter((component) => !component?.default && component.enabled)
    .map((cp) => ({
      key: cp?.component?.filterKey || "",
      label: cp?.component?.label || "",
      icon: cp?.component?.iconName || "",
      disabled: cp?.disabled || false,
    }))
    .filter((filter) => !permanentKeys.includes(filter.key));

  const scrollLeft = () => {
    if (
      contentRef.current.children?.[scrollIndex - 1]?.scrollWidth !== undefined &&
      contentRef.current?.scrollLeft !== undefined
    ) {
      const scrollDistance = contentRef.current.children[scrollIndex - 1].scrollWidth + 8;
      if (contentRef.current.scrollLeft > 0) {
        contentRef.current.scrollLeft -= scrollDistance;
        setScrollIndex((prev) => prev - 1);
      }
    }
  };

  const scrollRight = () => {
    if (
      contentRef.current.children?.[scrollIndex]?.scrollWidth !== undefined &&
      contentRef.current?.scrollLeft !== undefined
    ) {
      const scrollDistance = contentRef.current.children[scrollIndex].scrollWidth + 8;
      if (
        Math.abs(contentRef.current.scrollLeft + contentRef.current.offsetWidth - contentRef.current.scrollWidth) > 1
      ) {
        contentRef.current.scrollLeft += scrollDistance;
        setScrollIndex((prev) => prev + 1);
      }
    }
  };
  const handleSetSeparator = (entry) => {
    const updatedFilters = (filters?.newFilters && { ...filters.newFilters, separator: entry }) || {
      ...filters,
      separator: entry,
    };
    setSeparator(entry);
    setFilters(updatedFilters);
    onApply(updatedFilters);
  };
  const handleClickLaunch = () => {
    onApply(filters);
  };
  const handleKeyDown = (evt) => {
    if (evt.repeat) {
      return;
    }
    if (evt.key === "Enter") {
      const autocompleteOpen = document.querySelector("[role='listbox'][class*='_mentions__suggestions__list_']");
      if (!autocompleteOpen) {
        handleClickLaunch();
      }
    }
  };
  const renderFilters = () => (
    <div
      className={`${styles.innerWrapper} ${hasLargeSearch && styles.innerWrapperLargeSearch} ${
        collapsableFilters && styles.innerWrapperCollapsableFilters
      }`}
    >
      {hasSearch && (
        <>
          <FilterSearch
            className={searchClassName}
            label={searchLabel}
            placeholder={searchPlaceHolder}
            search={search}
            onSearch={handleTextSearch}
          />
          {hasSeparator && <VerticalDivider className={styles.divider} />}
        </>
      )}
      {!multiline && isNonEmptyArray(additionalFilters) && (
        <FiltersSelector
          addFilterButtonVariant={addFilterButtonVariant}
          className={styles["addButton--withMargin"]}
          disabledKeys={additionalFilters
            .map((f) => f.key)
            .filter((key) => displayedKeys.some((displayKey) => displayKey.name === key))}
          filters={additionalFilters}
          onClickKey={handleClickKey}
        />
      )}
      {hasSeparator && (
        <>
          <FilterSelector
            disableOr={
              filters?.tocFilters?.filters.some(
                (tocFilter) =>
                  tocFilter.operator === KEYWORD_FILTER_OPERATOR.IS_NOT_EXACTLY ||
                  tocFilter.operator === KEYWORD_FILTER_OPERATOR.START_NOT_WITH
              ) ||
              filters?.keywordFilters?.filters.some(
                (tocFilter) =>
                  tocFilter.operator === KEYWORD_FILTER_OPERATOR.IS_NOT_EXACTLY ||
                  tocFilter.operator === KEYWORD_FILTER_OPERATOR.START_NOT_WITH
              )
            }
            itemClassName={styles.separator__item}
            itemDisabledClassName={styles["separator__item--disabled"]}
            itemRenderer={(value) => (
              <div className={styles.separator__container}>
                <div className={styles.separator__title}>
                  {translate(`common:enum.separator.${value.toLowerCase()}`)}
                </div>
                <div className={styles.separator__helper}>
                  {translate(`common:filters.label.separator.${value.toLowerCase()}.sublabel`)}
                </div>
              </div>
            )}
            items={Object.values(OPERATORS)}
            selectionRenderer={(value) => translate(`common:enum.separator.${value.toLowerCase()}`)}
            size="md"
            value={separator}
            onChange={handleSetSeparator}
          />
          {!hasSearch && <VerticalDivider className={styles.divider} />}
        </>
      )}
      {!multiline && showArrows && (
        <CustomIconButton icon={icon.faChevronLeft} size="sm" variant="outlined" onClick={scrollLeft} />
      )}
      <div ref={contentRef} className={styles.carouselContainer} data-multiline={multiline}>
        {getIndividualAppliedFilters().map((child) => renderChild(child))}
        {multiline && isNonEmptyArray(additionalFilters) && (
          <FiltersSelector
            addFilterButtonVariant={addFilterButtonVariant}
            disabledKeys={additionalFilters
              .map((f) => f.key)
              .filter((key) => displayedKeys.some((displayKey) => displayKey.name === key))}
            filters={additionalFilters}
            onClickKey={handleClickKey}
          />
        )}
      </div>
      {!multiline && showArrows && (
        <CustomIconButton icon={icon.faChevronRight} size="sm" variant="outlined" onClick={scrollRight} />
      )}
    </div>
  );

  return (
    <>
      <div
        ref={containerRef}
        className={`${styles.wrapper} ${className}`}
        data-qaopen={!disableWidthAdjustments && Array.isArray(searchData) && searchData.length > 0}
      >
        {hasLargeSearch /*&& isFeatureEnabled(Flags.SEARCH_BAR)*/ && (
          <div className={styles.largeSearchWrapper}>
            <FilterSearch
              autocomplete
              className={searchClassName}
              debounceSearch={false}
              label={searchLabel}
              placeholder={searchPlaceHolder}
              search={search}
              onKeyDown={handleKeyDown}
              onSearch={handleTextSearch}
            />
            <div className={`${styles.largeSearchStartSearch}`}>
              <CustomButton color="primary" size="md" variant="contained" onClick={handleClickLaunch}>
                {translate("common:btn.search")}
              </CustomButton>
            </div>
          </div>
        )}
        {collapsableFilters ? (
          <CustomAccordion
            className={styles.accordionContent}
            header={
              <div className={styles.accordationTitle}>
                {translate("common:filters.collapsable.advanced-search")} {filtersCount > 0 ? `(${filtersCount})` : ""}
              </div>
            }
          >
            {renderFilters()}
          </CustomAccordion>
        ) : (
          renderFilters()
        )}
      </div>
      {Array.isArray(secondaryComponents) && secondaryComponents.length > 0 && (
        <div className={styles.carouselContainer} data-multiline={multiline}>
          {secondaryComponents
            .filter(
              (cpt) => cpt?.default || displayedKeys.some((displayKey) => displayKey.name === cpt.component.filterKey)
            )
            .map((child) => renderChild(child))}
        </div>
      )}
    </>
  );
}
