import React from "react";
import styles from "../EnvironmentSelection.module.css";
import { CustomButton, InfiniteList2 } from "../../../../../common/components";
import { translate } from "../../../../../common/providers";

const SelectionContainer = ({
  disableValidate,
  headerRow,
  onBack,
  onValidate,
  request,
  rowRenderer,
  searchPlaceholder,
  title,
}) => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>{title}</div>
      <InfiniteList2
        hasSearch
        className={styles.infiniteListContainer}
        headerRow={headerRow}
        request={request}
        rowRenderer={rowRenderer}
        searchPlaceholder={searchPlaceholder}
      />
      <div className={styles.actionBtnContainer}>
        <CustomButton color="secondary" variant="outlined" onClick={onBack}>
          {translate("common:btn.back")}
        </CustomButton>
        <CustomButton disabled={disableValidate} variant="contained" onClick={onValidate}>
          {translate("common:btn.validate")}
        </CustomButton>
      </div>
    </div>
  );
};

export default SelectionContainer;
